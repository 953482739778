export const annotationService = {
  AddAnnotations: (docuViewareID: string | null | undefined, param: any, callback: any) => {
    const root = DocuViewareAPI.Root(docuViewareID);
    localStorage.getItem('');
    root.AddAnnotCustom(
      param,
      (res: any) => {
        if (callback) {
          callback(res, param);
        }
      },
      null,
      null,
    );
  },
};
