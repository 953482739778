/* tslint:disable */
/* eslint-disable */
/**
 * LegalXtract API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'access_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'refresh_token'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    'token_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AccessToken
     */
    'expires_in'?: number;
    /**
     * 
     * @type {UserInfo}
     * @memberof AccessToken
     */
    'user_info'?: UserInfo;
}
/**
 * 
 * @export
 * @interface AccessTokenOperationResult
 */
export interface AccessTokenOperationResult {
    /**
     * 
     * @type {AccessToken}
     * @memberof AccessTokenOperationResult
     */
    'result'?: AccessToken;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AccessTokenOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccessTokenOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface AddBoxParam
 */
export interface AddBoxParam {
    /**
     * 
     * @type {string}
     * @memberof AddBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddBoxParam
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface AddLineParam
 */
export interface AddLineParam {
    /**
     * 
     * @type {string}
     * @memberof AddLineParam
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'borderWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof AddLineParam
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof AddLineParam
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface AddReplaceBoxParam
 */
export interface AddReplaceBoxParam {
    /**
     * 
     * @type {ReplaceParam}
     * @memberof AddReplaceBoxParam
     */
    'box'?: ReplaceParam;
    /**
     * 
     * @type {string}
     * @memberof AddReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof AddReplaceBoxParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface AddTagCategoryParam
 */
export interface AddTagCategoryParam {
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddTagCategoryParam
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface AddTagNameParam
 */
export interface AddTagNameParam {
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'tagName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddTagNameParam
     */
    'tagCategoryId'?: string;
}
/**
 * 
 * @export
 * @interface ApprovalStampInfo
 */
export interface ApprovalStampInfo {
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'stampTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'approvalStampDesignRepx'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampInfo
     */
    'transparent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovalStampInfo
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {ApprovalStampLocation}
     * @memberof ApprovalStampInfo
     */
    'location'?: ApprovalStampLocation;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'docNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampInfo
     */
    'createdBy'?: string | null;
}
/**
 * 
 * @export
 * @interface ApprovalStampLocation
 */
export interface ApprovalStampLocation {
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampLocation
     */
    'centerX'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampLocation
     */
    'centerY'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampLocation
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampLocation
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface ApprovalStampTemplate
 */
export interface ApprovalStampTemplate {
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplate
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampTemplate
     */
    'widthCm'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplate
     */
    'approvalStampDesignRepx'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampTemplate
     */
    'transparent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovalStampTemplate
     */
    'addFrame'?: boolean;
}
/**
 * 
 * @export
 * @interface ApprovalStampTemplateListLegalManagerParam
 */
export interface ApprovalStampTemplateListLegalManagerParam {
    /**
     * 
     * @type {Array<ApprovalStampTemplate>}
     * @memberof ApprovalStampTemplateListLegalManagerParam
     */
    'data'?: Array<ApprovalStampTemplate> | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplateListLegalManagerParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface ApprovalStampTemplateWeb
 */
export interface ApprovalStampTemplateWeb {
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplateWeb
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplateWeb
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampTemplateWeb
     */
    'widthCm'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplateWeb
     */
    'approvalStampDesignRepx'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampTemplateWeb
     */
    'transparent'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApprovalStampTemplateWeb
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampTemplateWeb
     */
    'image'?: string | null;
}
/**
 * 
 * @export
 * @interface ApprovalStampViewItem
 */
export interface ApprovalStampViewItem {
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampViewItem
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'stampTemplateId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'approvalStampDesignRepx'?: string | null;
    /**
     * 
     * @type {ApprovalStampLocation}
     * @memberof ApprovalStampViewItem
     */
    'location'?: ApprovalStampLocation;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'docNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalStampViewItem
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApprovalStampViewItem
     */
    'transparent'?: number;
}
/**
 * 
 * @export
 * @interface BookmarkObj
 */
export interface BookmarkObj {
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {PointF}
     * @memberof BookmarkObj
     */
    'position'?: PointF;
    /**
     * 
     * @type {string}
     * @memberof BookmarkObj
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface BooleanApiResult
 */
export interface BooleanApiResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'data'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BooleanApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface BooleanOperationResult
 */
export interface BooleanOperationResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'result'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface BoxObj
 */
export interface BoxObj {
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'id'?: string;
    /**
     * 
     * @type {RectangleF}
     * @memberof BoxObj
     */
    'rect'?: RectangleF;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof BoxObj
     */
    'replaceObjId'?: string | null;
}
/**
 * 
 * @export
 * @interface CalculateWatchDogParam
 */
export interface CalculateWatchDogParam {
    /**
     * 
     * @type {WatchDogParamWeb}
     * @memberof CalculateWatchDogParam
     */
    'param'?: WatchDogParamWeb;
    /**
     * 
     * @type {SearchTemplateParam}
     * @memberof CalculateWatchDogParam
     */
    'searchTemplateParam'?: SearchTemplateParam;
}
/**
 * 
 * @export
 * @interface CalculateWatchDogResponse
 */
export interface CalculateWatchDogResponse {
    /**
     * 
     * @type {Array<GroupWatchDogObj>}
     * @memberof CalculateWatchDogResponse
     */
    'groupWatchDogs'?: Array<GroupWatchDogObj> | null;
    /**
     * 
     * @type {Array<WatchDogObj>}
     * @memberof CalculateWatchDogResponse
     */
    'watchDogs'?: Array<WatchDogObj> | null;
}
/**
 * 
 * @export
 * @interface ChangePassWordParam
 */
export interface ChangePassWordParam {
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'passWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'currentPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePassWordParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CharType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type CharType = typeof CharType[keyof typeof CharType];


/**
 * 
 * @export
 * @interface CheckDuplicateResponse
 */
export interface CheckDuplicateResponse {
    /**
     * 
     * @type {Array<DuplicateThumbnail>}
     * @memberof CheckDuplicateResponse
     */
    'thumbnails'?: Array<DuplicateThumbnail> | null;
    /**
     * 
     * @type {{ [key: string]: Array<number>; }}
     * @memberof CheckDuplicateResponse
     */
    'dicPageDump'?: { [key: string]: Array<number>; } | null;
}
/**
 * 
 * @export
 * @interface CheckDuplicateSettings
 */
export interface CheckDuplicateSettings {
    /**
     * 
     * @type {boolean}
     * @memberof CheckDuplicateSettings
     */
    'isCurrentDocument'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CheckDuplicateSettings
     */
    'includeHideOnExtract'?: boolean;
}
/**
 * 
 * @export
 * @interface CheckUserStatusParam
 */
export interface CheckUserStatusParam {
    /**
     * 
     * @type {string}
     * @memberof CheckUserStatusParam
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CheckUserStatusParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface CommentObjDisplay
 */
export interface CommentObjDisplay {
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {LxRange}
     * @memberof CommentObjDisplay
     */
    'range'?: LxRange;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'boxId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'createdDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'comment'?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof CommentObjDisplay
     */
    'status'?: CommentStatusEnum;
    /**
     * 
     * @type {CommentTypeEnum}
     * @memberof CommentObjDisplay
     */
    'commentTypeEnum'?: CommentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isSelected'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isCollapse'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentObjDisplay
     */
    'isHidden'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof CommentObjDisplay
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommentObjDisplay
     */
    'pageNoStr'?: string | null;
}


/**
 * 
 * @export
 * @interface CommentObjDisplayWeb
 */
export interface CommentObjDisplayWeb {
    /**
     * 
     * @type {CommentObjDisplay}
     * @memberof CommentObjDisplayWeb
     */
    'commentObjDisplay'?: CommentObjDisplay;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof CommentObjDisplayWeb
     */
    'rectangleFs'?: Array<RectangleF> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CommentOrderByEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CommentOrderByEnum = typeof CommentOrderByEnum[keyof typeof CommentOrderByEnum];


/**
 * 
 * @export
 * @interface CommentParam
 */
export interface CommentParam {
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'comment'?: string | null;
    /**
     * 
     * @type {CommentStatusEnum}
     * @memberof CommentParam
     */
    'status'?: CommentStatusEnum;
    /**
     * 
     * @type {CommentTypeEnum}
     * @memberof CommentParam
     */
    'type'?: CommentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommentParam
     */
    'boxId'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type CommentStatusEnum = typeof CommentStatusEnum[keyof typeof CommentStatusEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const CommentTypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type CommentTypeEnum = typeof CommentTypeEnum[keyof typeof CommentTypeEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const Country = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_4: 4,
    NUMBER_8: 8,
    NUMBER_32768: 32768
} as const;

export type Country = typeof Country[keyof typeof Country];


/**
 * 
 * @export
 * @interface CreateBlankFileParam
 */
export interface CreateBlankFileParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateBlankFileParam
     */
    'selectedTabIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBlankFileParam
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface CustomTemplateItem
 */
export interface CustomTemplateItem {
    /**
     * 
     * @type {boolean}
     * @memberof CustomTemplateItem
     */
    'selected'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateItem
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomTemplateItem
     */
    'value'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomTemplateItem
     */
    'isRegex'?: boolean;
}
/**
 * 
 * @export
 * @interface DeletePagesParam
 */
export interface DeletePagesParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof DeletePagesParam
     */
    'pageNos'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface DocuViewareRESTOutputResponse
 */
export interface DocuViewareRESTOutputResponse {
    /**
     * 
     * @type {number}
     * @memberof DocuViewareRESTOutputResponse
     */
    'totalPage'?: number;
    /**
     * 
     * @type {string}
     * @memberof DocuViewareRESTOutputResponse
     */
    'clientID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocuViewareRESTOutputResponse
     */
    'htmlContent'?: string | null;
}
/**
 * 
 * @export
 * @interface DocuviewareInit
 */
export interface DocuviewareInit {
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInit
     */
    'docuViewareID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInit
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInit
     */
    'htmlString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInit
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DocuviewareInit
     */
    'totalPage'?: number;
    /**
     * 
     * @type {Array<TabDetail>}
     * @memberof DocuviewareInit
     */
    'tabDetails'?: Array<TabDetail> | null;
    /**
     * 
     * @type {Array<Page>}
     * @memberof DocuviewareInit
     */
    'pages'?: Array<Page> | null;
    /**
     * 
     * @type {TabStampWeb}
     * @memberof DocuviewareInit
     */
    'tabStampWeb'?: TabStampWeb;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'isUploadFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'isCreateFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'isIncludedPageNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'showStamp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'isBlankPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'isTOC'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInit
     */
    'documentHasChanged'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInit
     */
    'fixedSplitInPages'?: string | null;
}
/**
 * 
 * @export
 * @interface DocuviewareInitApiResult
 */
export interface DocuviewareInitApiResult {
    /**
     * 
     * @type {DocuviewareInit}
     * @memberof DocuviewareInitApiResult
     */
    'data'?: DocuviewareInit;
    /**
     * 
     * @type {boolean}
     * @memberof DocuviewareInitApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInitApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DocuviewareInitApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface DuplicateThumbnail
 */
export interface DuplicateThumbnail {
    /**
     * 
     * @type {number}
     * @memberof DuplicateThumbnail
     */
    'viewPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof DuplicateThumbnail
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof DuplicateThumbnail
     */
    'imageBase'?: string | null;
    /**
     * 
     * @type {HiddenWithDeleteEnum}
     * @memberof DuplicateThumbnail
     */
    'status'?: HiddenWithDeleteEnum;
}


/**
 * 
 * @export
 * @interface EditReplaceBoxParam
 */
export interface EditReplaceBoxParam {
    /**
     * 
     * @type {ReplaceParam}
     * @memberof EditReplaceBoxParam
     */
    'replaceParam'?: ReplaceParam;
    /**
     * 
     * @type {string}
     * @memberof EditReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof EditReplaceBoxParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface EditTocHeaderParam
 */
export interface EditTocHeaderParam {
    /**
     * 
     * @type {string}
     * @memberof EditTocHeaderParam
     */
    'text'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EditTocHeaderParam
     */
    'editTitle'?: boolean;
    /**
     * 
     * @type {TocColumEnum}
     * @memberof EditTocHeaderParam
     */
    'tocColumEnumType'?: TocColumEnum;
}


/**
 * 
 * @export
 * @interface ExtractAndSplitParam
 */
export interface ExtractAndSplitParam {
    /**
     * 
     * @type {boolean}
     * @memberof ExtractAndSplitParam
     */
    'highlightedSelections'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtractAndSplitParam
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractAndSplitParam
     */
    'fixedSplitInPages'?: string | null;
    /**
     * 
     * @type {PdfFileType}
     * @memberof ExtractAndSplitParam
     */
    'pdfFileType'?: PdfFileType;
}


/**
 * 
 * @export
 * @interface ExtractSplit
 */
export interface ExtractSplit {
    /**
     * 
     * @type {number}
     * @memberof ExtractSplit
     */
    'offset'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractSplit
     */
    'totalPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtractSplit
     */
    'fileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtractSplit
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExtractSplit
     */
    'imgFile'?: string | null;
}
/**
 * 
 * @export
 * @interface FrontPageContentInfo
 */
export interface FrontPageContentInfo {
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'content'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontPageContentInfo
     */
    'logo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'logoHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'logoWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontPageContentInfo
     */
    'visibility'?: number;
}
/**
 * 
 * @export
 * @interface GetApprovalStampImageParam
 */
export interface GetApprovalStampImageParam {
    /**
     * 
     * @type {string}
     * @memberof GetApprovalStampImageParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalStampImageParam
     */
    'docNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalStampImageParam
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetApprovalStampImageParam
     */
    'approvalStampDesignRepx'?: string | null;
}
/**
 * 
 * @export
 * @interface GetLanguagesResponse
 */
export interface GetLanguagesResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GetLanguagesResponse
     */
    'languageData'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {DocuviewareInit}
     * @memberof GetLanguagesResponse
     */
    'docuviewareInit'?: DocuviewareInit;
}
/**
 * 
 * @export
 * @interface GetSelectedWordContinuousParam
 */
export interface GetSelectedWordContinuousParam {
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetSelectedWordContinuousParam
     */
    'y'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSelectedWordContinuousParam
     */
    'shift'?: boolean;
}
/**
 * 
 * @export
 * @interface GetXmlPreviewItemResponse
 */
export interface GetXmlPreviewItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetXmlPreviewItemResponse
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetXmlPreviewItemResponse
     */
    'isCurrentTab'?: boolean;
}
/**
 * 
 * @export
 * @interface GroupWatchDogObj
 */
export interface GroupWatchDogObj {
    /**
     * 
     * @type {string}
     * @memberof GroupWatchDogObj
     */
    'key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GroupWatchDogObj
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GroupWatchDogObj
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupWatchDogObj
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupWatchDogObj
     */
    'level'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GroupWatchDogObj
     */
    'isCollapsed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GroupWatchDogObj
     */
    'searchTypeKey'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HiddenEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type HiddenEnum = typeof HiddenEnum[keyof typeof HiddenEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const HiddenWithDeleteEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type HiddenWithDeleteEnum = typeof HiddenWithDeleteEnum[keyof typeof HiddenWithDeleteEnum];


/**
 * 
 * @export
 * @interface HighLightOrUnHighLightWords
 */
export interface HighLightOrUnHighLightWords {
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof HighLightOrUnHighLightWords
     */
    'rectangleSelection'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLightWords
     */
    'mode'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightOrUnHighLightWords
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface HighLightUnHighLightLine
 */
export interface HighLightUnHighLightLine {
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof HighLightUnHighLightLine
     */
    'pageNo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HighLightUnHighLightLine
     */
    'isHotKey'?: boolean;
}
/**
 * 
 * @export
 * @interface HighlightSearchResultsParam
 */
export interface HighlightSearchResultsParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof HighlightSearchResultsParam
     */
    'sortKeys'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HighlightSearchResultsParam
     */
    'isSelectAll'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HighlightSearchResultsParam
     */
    'isHighlight'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HistoryItemType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type HistoryItemType = typeof HistoryItemType[keyof typeof HistoryItemType];


/**
 * 
 * @export
 * @interface HistoryView
 */
export interface HistoryView {
    /**
     * 
     * @type {Array<HistoryViewItemWeb>}
     * @memberof HistoryView
     */
    'groups'?: Array<HistoryViewItemWeb> | null;
    /**
     * 
     * @type {Array<HistoryViewItemWeb>}
     * @memberof HistoryView
     */
    'items'?: Array<HistoryViewItemWeb> | null;
}
/**
 * 
 * @export
 * @interface HistoryViewItemWeb
 */
export interface HistoryViewItemWeb {
    /**
     * 
     * @type {HistoryItemType}
     * @memberof HistoryViewItemWeb
     */
    'type'?: HistoryItemType;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'pageNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HistoryViewItemWeb
     */
    'dateTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'startIndex'?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'count'?: number;
    /**
     * 
     * @type {number}
     * @memberof HistoryViewItemWeb
     */
    'level'?: number;
}


/**
 * 
 * @export
 * @interface ImageDetail
 */
export interface ImageDetail {
    /**
     * 
     * @type {string}
     * @memberof ImageDetail
     */
    'pageId'?: string;
    /**
     * 
     * @type {number}
     * @memberof ImageDetail
     */
    'pageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof ImageDetail
     */
    'xtractPageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageDetail
     */
    'imageBase'?: string | null;
    /**
     * 
     * @type {HiddenEnum}
     * @memberof ImageDetail
     */
    'isHidden'?: HiddenEnum;
    /**
     * 
     * @type {ThumbnailType}
     * @memberof ImageDetail
     */
    'type'?: ThumbnailType;
    /**
     * 
     * @type {boolean}
     * @memberof ImageDetail
     */
    'isVerticalView'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageDetail
     */
    'isOCRDone'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageDetail
     */
    'needOCR'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ImageDetail
     */
    'splitOnExtract'?: boolean;
}


/**
 * 
 * @export
 * @interface Int32LegalManagerParam
 */
export interface Int32LegalManagerParam {
    /**
     * 
     * @type {number}
     * @memberof Int32LegalManagerParam
     */
    'data'?: number;
    /**
     * 
     * @type {string}
     * @memberof Int32LegalManagerParam
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface JoinDocumentsParam
 */
export interface JoinDocumentsParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof JoinDocumentsParam
     */
    'tabIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface JoinDocumentsResponse
 */
export interface JoinDocumentsResponse {
    /**
     * 
     * @type {DocuviewareInit}
     * @memberof JoinDocumentsResponse
     */
    'docuviewareInit'?: DocuviewareInit;
    /**
     * 
     * @type {Array<TocRow>}
     * @memberof JoinDocumentsResponse
     */
    'tocRows'?: Array<TocRow> | null;
}
/**
 * 
 * @export
 * @interface LegalParam
 */
export interface LegalParam {
    /**
     * 
     * @type {HighlightSearchResultsParam}
     * @memberof LegalParam
     */
    'highlightSearchResultsParam'?: HighlightSearchResultsParam;
    /**
     * 
     * @type {AddReplaceBoxParam}
     * @memberof LegalParam
     */
    'addReplaceBox'?: AddReplaceBoxParam;
    /**
     * 
     * @type {HighLightUnHighLightLine}
     * @memberof LegalParam
     */
    'highLightUnHighLightLine'?: HighLightUnHighLightLine;
    /**
     * 
     * @type {HighLightOrUnHighLightWords}
     * @memberof LegalParam
     */
    'highLightOrUnHighLightWords'?: HighLightOrUnHighLightWords;
    /**
     * 
     * @type {UnSelectedAllSelectAllRowParam}
     * @memberof LegalParam
     */
    'unSelectedAllSelectAll'?: UnSelectedAllSelectAllRowParam;
    /**
     * 
     * @type {WordInPage}
     * @memberof LegalParam
     */
    'wordInPage'?: WordInPage;
    /**
     * 
     * @type {ApprovalStampInfo}
     * @memberof LegalParam
     */
    'approvalStampInfo'?: ApprovalStampInfo;
}
/**
 * 
 * @export
 * @interface LicenseConfig
 */
export interface LicenseConfig {
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof LicenseConfig
     */
    'companyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LicenseConfig
     */
    'moduleKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LicenseInfoWeb
 */
export interface LicenseInfoWeb {
    /**
     * 
     * @type {string}
     * @memberof LicenseInfoWeb
     */
    'licenseOrganization'?: string | null;
    /**
     * 
     * @type {LicenseMode}
     * @memberof LicenseInfoWeb
     */
    'licenseMode'?: LicenseMode;
    /**
     * 
     * @type {string}
     * @memberof LicenseInfoWeb
     */
    'licenseEndDate'?: string | null;
    /**
     * 
     * @type {Array<LicenseConfig>}
     * @memberof LicenseInfoWeb
     */
    'modules'?: Array<LicenseConfig> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LicenseMode = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type LicenseMode = typeof LicenseMode[keyof typeof LicenseMode];


/**
 * 
 * @export
 * @interface LicenseReponseResult
 */
export interface LicenseReponseResult {
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'mode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LicenseReponseResult
     */
    'update'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'company_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LicenseReponseResult
     */
    'passwordFileXtract'?: string | null;
    /**
     * 
     * @type {Array<LicenseConfig>}
     * @memberof LicenseReponseResult
     */
    'modules'?: Array<LicenseConfig> | null;
}
/**
 * 
 * @export
 * @interface LoginAppParam
 */
export interface LoginAppParam {
    /**
     * 
     * @type {string}
     * @memberof LoginAppParam
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginAppParam
     */
    'passWord'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginNovaParam
 */
export interface LoginNovaParam {
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'requestId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'tokenThirdParty'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginNovaParam
     */
    'licenseKey'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {AccessTokenOperationResult}
     * @memberof LoginResponse
     */
    'userDataLogin'?: AccessTokenOperationResult;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    'token'?: string | null;
}
/**
 * 
 * @export
 * @interface LoginResponseOperationResult
 */
export interface LoginResponseOperationResult {
    /**
     * 
     * @type {LoginResponse}
     * @memberof LoginResponseOperationResult
     */
    'result'?: LoginResponse;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface LxChar
 */
export interface LxChar {
    /**
     * 
     * @type {number}
     * @memberof LxChar
     */
    'id'?: number;
    /**
     * 
     * @type {RectangleF}
     * @memberof LxChar
     */
    'rect'?: RectangleF;
    /**
     * 
     * @type {number}
     * @memberof LxChar
     */
    'wordId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LxChar
     */
    'char'?: string;
    /**
     * 
     * @type {number}
     * @memberof LxChar
     */
    'paragraphIdx'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxChar
     */
    'lineIdx'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxChar
     */
    'logicLineIdx'?: number;
    /**
     * 
     * @type {MarkedInfo}
     * @memberof LxChar
     */
    'markedInfo'?: MarkedInfo;
}
/**
 * 
 * @export
 * @interface LxRange
 */
export interface LxRange {
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'fromId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'toId'?: number;
    /**
     * 
     * @type {number}
     * @memberof LxRange
     */
    'length'?: number;
}
/**
 * 
 * @export
 * @interface LxSetting
 */
export interface LxSetting {
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'licenseServiceUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'option_Color_selection'?: string;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'archiveFileFormat'?: number;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'drawLineWidth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'drawLineColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'extractCombineTab'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'extractMaxFileSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'autoHighlightMask'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'ocrWhenOpeningDocuments'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'replaceFontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'replaceFontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'compressExtractFiles'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningKeyword'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningTemplate'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'warningTag'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'isFavorSpeed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'ocrDictionary'?: string | null;
    /**
     * 
     * @type {PdfFileType}
     * @memberof LxSetting
     */
    'extractPdfFileType'?: PdfFileType;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'tocIndexPattern'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'drawIndexInTocPages'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'namesWillNotBeginOrEndWith'?: string | null;
    /**
     * 
     * @type {Country}
     * @memberof LxSetting
     */
    'dictionaryName'?: Country;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'highlightPublishColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof LxSetting
     */
    'dontSendOCRMailPageCount'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'sendEmailAfterPublish'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'sendEmailTo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'hiddenPageTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'hiddenPageImage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'showWaterMark'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'waterMarkTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'waterMarkImage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LxSetting
     */
    'checkDuplicate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LxSetting
     */
    'defaultPublishFolder'?: string | null;
}


/**
 * 
 * @export
 * @interface LxWordResponse
 */
export interface LxWordResponse {
    /**
     * 
     * @type {number}
     * @memberof LxWordResponse
     */
    'id'?: number;
    /**
     * 
     * @type {CharType}
     * @memberof LxWordResponse
     */
    'charType'?: CharType;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof LxWordResponse
     */
    'rects'?: Array<RectangleF> | null;
    /**
     * 
     * @type {number}
     * @memberof LxWordResponse
     */
    'logicLineId'?: number;
    /**
     * 
     * @type {string}
     * @memberof LxWordResponse
     */
    'text'?: string | null;
}


/**
 * 
 * @export
 * @interface MarkedInfo
 */
export interface MarkedInfo {
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'author'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'creationTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'replaceObjId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'charRangeId'?: string;
    /**
     * 
     * @type {SearchType}
     * @memberof MarkedInfo
     */
    'searchType'?: SearchType;
    /**
     * 
     * @type {string}
     * @memberof MarkedInfo
     */
    'searchTypeKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MarkedInfo
     */
    'isHighlightOnPublish'?: boolean;
}


/**
 * 
 * @export
 * @interface NoteTextObj
 */
export interface NoteTextObj {
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'noteText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'foreColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NoteTextObj
     */
    'fontSize'?: number;
    /**
     * 
     * @type {PointF}
     * @memberof NoteTextObj
     */
    'point'?: PointF;
    /**
     * 
     * @type {number}
     * @memberof NoteTextObj
     */
    'opacity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextObj
     */
    'visibleOnExtract'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NoteTextObj
     */
    'createDate'?: string;
}
/**
 * 
 * @export
 * @interface NoteTextParam
 */
export interface NoteTextParam {
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'pageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'noteText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'foreColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof NoteTextParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'opacity'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof NoteTextParam
     */
    'visibleOnExtract'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof NoteTextParam
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OcrMode = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type OcrMode = typeof OcrMode[keyof typeof OcrMode];


/**
 * 
 * @export
 * @interface OcrProcessParam
 */
export interface OcrProcessParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof OcrProcessParam
     */
    'tabId'?: Array<string> | null;
    /**
     * 
     * @type {OcrMode}
     * @memberof OcrProcessParam
     */
    'ocrMode'?: OcrMode;
    /**
     * 
     * @type {string}
     * @memberof OcrProcessParam
     */
    'ocrId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OcrProcessParam
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OcrProcessParam
     */
    'pages'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface Page
 */
export interface Page {
    /**
     * 
     * @type {string}
     * @memberof Page
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageId'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageImageCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'rotationAngles'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof Page
     */
    'pageHeight'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Page
     */
    'isBlankPage'?: boolean;
    /**
     * 
     * @type {TabFileType}
     * @memberof Page
     */
    'tabFileType'?: TabFileType;
    /**
     * 
     * @type {Array<string>}
     * @memberof Page
     */
    'bookMarkIds'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface PageNumberingInfo
 */
export interface PageNumberingInfo {
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'included'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'margin'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'marginSide'?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'position'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'showFromTOC'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'showFromFrontPage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNumberingInfo
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PageNumberingInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof PageNumberingInfo
     */
    'backColor'?: string;
}
/**
 * 
 * @export
 * @interface PageStatus
 */
export interface PageStatus {
    /**
     * 
     * @type {number}
     * @memberof PageStatus
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {HiddenWithDeleteEnum}
     * @memberof PageStatus
     */
    'status'?: HiddenWithDeleteEnum;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PdfFileType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type PdfFileType = typeof PdfFileType[keyof typeof PdfFileType];


/**
 * 
 * @export
 * @interface PointF
 */
export interface PointF {
    /**
     * 
     * @type {boolean}
     * @memberof PointF
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PointF
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof PointF
     */
    'y'?: number;
}
/**
 * 
 * @export
 * @interface ReOrderTOCParam
 */
export interface ReOrderTOCParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReOrderTOCParam
     */
    'tocIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ReOrderTOCParam
     */
    'parentId'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReOrderTOCParam
     */
    'newOrderIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ReOrderTOCResponse
 */
export interface ReOrderTOCResponse {
    /**
     * 
     * @type {Array<TocRow>}
     * @memberof ReOrderTOCResponse
     */
    'tocRows'?: Array<TocRow> | null;
    /**
     * 
     * @type {DocuviewareInit}
     * @memberof ReOrderTOCResponse
     */
    'docuviewareInit'?: DocuviewareInit;
}
/**
 * 
 * @export
 * @interface ReadFileOcrParam
 */
export interface ReadFileOcrParam {
    /**
     * 
     * @type {string}
     * @memberof ReadFileOcrParam
     */
    'ocrId'?: string | null;
}
/**
 * 
 * @export
 * @interface RectangleF
 */
export interface RectangleF {
    /**
     * 
     * @type {PointF}
     * @memberof RectangleF
     */
    'location'?: PointF;
    /**
     * 
     * @type {SizeF}
     * @memberof RectangleF
     */
    'size'?: SizeF;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'right'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleF
     */
    'bottom'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RectangleF
     */
    'isEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface RectangleSelection
 */
export interface RectangleSelection {
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'wordId'?: number;
    /**
     * 
     * @type {number}
     * @memberof RectangleSelection
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {RectangleF}
     * @memberof RectangleSelection
     */
    'rectangleF'?: RectangleF;
}
/**
 * 
 * @export
 * @interface ReplaceBoxObjs
 */
export interface ReplaceBoxObjs {
    /**
     * 
     * @type {Array<BoxObj>}
     * @memberof ReplaceBoxObjs
     */
    'boxs'?: Array<BoxObj> | null;
    /**
     * 
     * @type {Array<ReplaceObj>}
     * @memberof ReplaceBoxObjs
     */
    'replaceObjs'?: Array<ReplaceObj> | null;
}
/**
 * 
 * @export
 * @interface ReplaceBoxParam
 */
export interface ReplaceBoxParam {
    /**
     * 
     * @type {number}
     * @memberof ReplaceBoxParam
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceBoxParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceBoxParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceBoxParam
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceBoxParam
     */
    'code'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceObj
 */
export interface ReplaceObj {
    /**
     * 
     * @type {number}
     * @memberof ReplaceObj
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObj
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObj
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObj
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceObj
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObj
     */
    'code'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceObjDisplay
 */
export interface ReplaceObjDisplay {
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'pageId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'fontSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObjDisplay
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceObjDisplay
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {ReplaceType}
     * @memberof ReplaceObjDisplay
     */
    'replaceType'?: ReplaceType;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'replaceCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReplaceObjDisplay
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'cWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceObjDisplay
     */
    'cCount'?: string | null;
}


/**
 * 
 * @export
 * @interface ReplaceParam
 */
export interface ReplaceParam {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParam
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'extractColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParam
     */
    'isReplaceAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParam
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface ReplaceParamWeb
 */
export interface ReplaceParamWeb {
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'originalWords'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParamWeb
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'extractColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'isReplaceAll'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReplaceParamWeb
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReplaceParamWeb
     */
    'replaceWholeWord'?: boolean;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof ReplaceParamWeb
     */
    'rectangleWords'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReplaceParamWeb
     */
    'sortKeys'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ReplaceParamWeb
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface ReplaceTooltipWeb
 */
export interface ReplaceTooltipWeb {
    /**
     * 
     * @type {number}
     * @memberof ReplaceTooltipWeb
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof ReplaceTooltipWeb
     */
    'replaceObjId'?: string;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof ReplaceTooltipWeb
     */
    'rectangleWords'?: Array<RectangleSelection> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReplaceType = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ReplaceType = typeof ReplaceType[keyof typeof ReplaceType];


/**
 * 
 * @export
 * @interface RotateParam
 */
export interface RotateParam {
    /**
     * 
     * @type {Array<number>}
     * @memberof RotateParam
     */
    'viewerPageNos'?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RowStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type RowStatus = typeof RowStatus[keyof typeof RowStatus];


/**
 * 
 * @export
 * @interface SaveRedactPdfHookParam
 */
export interface SaveRedactPdfHookParam {
    /**
     * 
     * @type {string}
     * @memberof SaveRedactPdfHookParam
     */
    'documentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveRedactPdfHookParam
     */
    'filename'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaveRedactPdfHookParam
     */
    'files'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SaveRedactPdfHookParamOperationResult
 */
export interface SaveRedactPdfHookParamOperationResult {
    /**
     * 
     * @type {SaveRedactPdfHookParam}
     * @memberof SaveRedactPdfHookParamOperationResult
     */
    'result'?: SaveRedactPdfHookParam;
    /**
     * 
     * @type {boolean}
     * @memberof SaveRedactPdfHookParamOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveRedactPdfHookParamOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveRedactPdfHookParamOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveRedactPdfHookParamOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface SaveTagParam
 */
export interface SaveTagParam {
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'tagId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'categoryID'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'tagNameId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'keyWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveTagParam
     */
    'backColor'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'comma'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'line'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'semicolon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'slash'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveTagParam
     */
    'space'?: boolean;
}
/**
 * 
 * @export
 * @interface SaveXtractParam
 */
export interface SaveXtractParam {
    /**
     * 
     * @type {string}
     * @memberof SaveXtractParam
     */
    'documentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveXtractParam
     */
    'filename'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaveXtractParam
     */
    'file'?: string | null;
}
/**
 * 
 * @export
 * @interface SaveXtractParamOperationResult
 */
export interface SaveXtractParamOperationResult {
    /**
     * 
     * @type {SaveXtractParam}
     * @memberof SaveXtractParamOperationResult
     */
    'result'?: SaveXtractParam;
    /**
     * 
     * @type {boolean}
     * @memberof SaveXtractParamOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaveXtractParamOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SaveXtractParamOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SaveXtractParamOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchKeywordApiParam
 */
export interface SearchKeywordApiParam {
    /**
     * 
     * @type {SearchKeywordParam}
     * @memberof SearchKeywordApiParam
     */
    'searchKeywordParam'?: SearchKeywordParam;
    /**
     * 
     * @type {SearchTabVm}
     * @memberof SearchKeywordApiParam
     */
    'searchTabVm'?: SearchTabVm;
    /**
     * 
     * @type {number}
     * @memberof SearchKeywordApiParam
     */
    'currentPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SearchKeywordParam
 */
export interface SearchKeywordParam {
    /**
     * 
     * @type {string}
     * @memberof SearchKeywordParam
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'wildCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'searchList'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'byComma'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySemicolon'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'byLine'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySpace'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'bySplash'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchKeywordParam
     */
    'highlightWholeWord'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'pageId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'sortKey'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'viewerPageNo'?: number;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'word'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'phrase'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResult
     */
    'checked'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'highLightFromIdx'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResult
     */
    'highLightLength'?: number;
    /**
     * 
     * @type {LxRange}
     * @memberof SearchResult
     */
    'range'?: LxRange;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'tagId'?: string | null;
    /**
     * 
     * @type {Array<RectangleF>}
     * @memberof SearchResult
     */
    'rectangleFs'?: Array<RectangleF> | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResult
     */
    'searchTypeKey'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchResultData
 */
export interface SearchResultData {
    /**
     * 
     * @type {Array<SearchResult>}
     * @memberof SearchResultData
     */
    'searchData'?: Array<SearchResult> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchResultData
     */
    'totalSearchResult'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchResultData
     */
    'totalPage'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchTabType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type SearchTabType = typeof SearchTabType[keyof typeof SearchTabType];


/**
 * 
 * @export
 * @interface SearchTabVm
 */
export interface SearchTabVm {
    /**
     * 
     * @type {SearchTabType}
     * @memberof SearchTabVm
     */
    'searchTabType'?: SearchTabType;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'autoSearch'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'accuracy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'fuzzy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTabVm
     */
    'allPages'?: boolean;
    /**
     * 
     * @type {SearchKeywordParam}
     * @memberof SearchTabVm
     */
    'searchKeywordParam'?: SearchKeywordParam;
    /**
     * 
     * @type {SearchTemplateParam}
     * @memberof SearchTabVm
     */
    'searchTemplateParam'?: SearchTemplateParam;
    /**
     * 
     * @type {SearchTagParam}
     * @memberof SearchTabVm
     */
    'searchTagParam'?: SearchTagParam;
}


/**
 * 
 * @export
 * @interface SearchTagParam
 */
export interface SearchTagParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof SearchTagParam
     */
    'selectedTags'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'accuracy'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'checkAll'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SearchTagParam
     */
    'pageNo'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTagParam
     */
    'highlightWholeWord'?: boolean;
}
/**
 * 
 * @export
 * @interface SearchTemplateApiParam
 */
export interface SearchTemplateApiParam {
    /**
     * 
     * @type {Array<TemplateItem>}
     * @memberof SearchTemplateApiParam
     */
    'templateItem'?: Array<TemplateItem> | null;
    /**
     * 
     * @type {Array<CustomTemplateItem>}
     * @memberof SearchTemplateApiParam
     */
    'customTemplateItems'?: Array<CustomTemplateItem> | null;
    /**
     * 
     * @type {SearchTabVm}
     * @memberof SearchTemplateApiParam
     */
    'searchTabVm'?: SearchTabVm;
    /**
     * 
     * @type {number}
     * @memberof SearchTemplateApiParam
     */
    'currentPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SearchTemplateParam
 */
export interface SearchTemplateParam {
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'phoneNumber'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'email'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'cpr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module11'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'bsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module97Bsn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'nrn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'module11Nrn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'cvr'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'creditCard'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchByName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchFullName'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'address'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'searchFullAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'assistiveProduct'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'belgianMunicipalities'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'policeZones'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'diseases'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'currency'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'number'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'date'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'webAddress'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchTemplateParam
     */
    'customTemplate'?: boolean;
    /**
     * 
     * @type {Array<TemplateItem>}
     * @memberof SearchTemplateParam
     */
    'templateItems'?: Array<TemplateItem> | null;
    /**
     * 
     * @type {Array<CustomTemplateItem>}
     * @memberof SearchTemplateParam
     */
    'customTemplateItems'?: Array<CustomTemplateItem> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SearchType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_999: 999
} as const;

export type SearchType = typeof SearchType[keyof typeof SearchType];


/**
 * 
 * @export
 * @interface SelectedWordContinuousResponse
 */
export interface SelectedWordContinuousResponse {
    /**
     * 
     * @type {string}
     * @memberof SelectedWordContinuousResponse
     */
    'value'?: string | null;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof SelectedWordContinuousResponse
     */
    'rectangleSelections'?: Array<RectangleSelection> | null;
}
/**
 * 
 * @export
 * @interface SelectionTagParam
 */
export interface SelectionTagParam {
    /**
     * 
     * @type {TagInfo}
     * @memberof SelectionTagParam
     */
    'tagInfo'?: TagInfo;
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof SelectionTagParam
     */
    'selectionBoxes'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {number}
     * @memberof SelectionTagParam
     */
    'viewerPageNo'?: number;
}
/**
 * 
 * @export
 * @interface SetSplitOnExtractParam
 */
export interface SetSplitOnExtractParam {
    /**
     * 
     * @type {boolean}
     * @memberof SetSplitOnExtractParam
     */
    'splitOnExtract'?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof SetSplitOnExtractParam
     */
    'pages'?: Array<number> | null;
}
/**
 * 
 * @export
 * @interface ShowHidePageParam
 */
export interface ShowHidePageParam {
    /**
     * 
     * @type {HiddenEnum}
     * @memberof ShowHidePageParam
     */
    'status'?: HiddenEnum;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShowHidePageParam
     */
    'pages'?: Array<number> | null;
}


/**
 * 
 * @export
 * @interface SizeF
 */
export interface SizeF {
    /**
     * 
     * @type {boolean}
     * @memberof SizeF
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SizeF
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof SizeF
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface StampLocation
 */
export interface StampLocation {
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'marginRight'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'marginTop'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof StampLocation
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StatusOpenFile = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type StatusOpenFile = typeof StatusOpenFile[keyof typeof StatusOpenFile];


/**
 * 
 * @export
 * @interface StringApiResult
 */
export interface StringApiResult {
    /**
     * 
     * @type {string}
     * @memberof StringApiResult
     */
    'data'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface StringListApiResult
 */
export interface StringListApiResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringListApiResult
     */
    'data'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringListApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringListApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringListApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface StringListOperationResult
 */
export interface StringListOperationResult {
    /**
     * 
     * @type {Array<string>}
     * @memberof StringListOperationResult
     */
    'result'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringListOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringListOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringListOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof StringListOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @interface TabDetail
 */
export interface TabDetail {
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'totalPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'startPage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {TabFileType}
     * @memberof TabDetail
     */
    'tabFileType'?: TabFileType;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageNeedOCR'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageOCRDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'numberPageHasImage'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabDetail
     */
    'index'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TabDetail
     */
    'includeStamp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabDetail
     */
    'isTocPageBreak'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabDetail
     */
    'stampIndex'?: string | null;
    /**
     * 
     * @type {TabStamp}
     * @memberof TabDetail
     */
    'tabStamp'?: TabStamp;
    /**
     * 
     * @type {boolean}
     * @memberof TabDetail
     */
    'isXml'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TabFileType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_3: 3
} as const;

export type TabFileType = typeof TabFileType[keyof typeof TabFileType];


/**
 * 
 * @export
 * @interface TabOcrResult
 */
export interface TabOcrResult {
    /**
     * 
     * @type {string}
     * @memberof TabOcrResult
     */
    'tabID'?: string;
    /**
     * 
     * @type {number}
     * @memberof TabOcrResult
     */
    'pageNumberDone'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabOcrResult
     */
    'pageNumberUnfinished'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabOcrResult
     */
    'totalPageOcrInTab'?: number;
    /**
     * 
     * @type {number}
     * @memberof TabOcrResult
     */
    'totalPageOcr'?: number;
}
/**
 * 
 * @export
 * @interface TabStamp
 */
export interface TabStamp {
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'index'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TabStamp
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStamp
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabStamp
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStamp
     */
    'transparent'?: number;
    /**
     * 
     * @type {StampLocation}
     * @memberof TabStamp
     */
    'location'?: StampLocation;
    /**
     * 
     * @type {boolean}
     * @memberof TabStamp
     */
    'autoScale'?: boolean;
}
/**
 * 
 * @export
 * @interface TabStampWeb
 */
export interface TabStampWeb {
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'index'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'addFrame'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStampWeb
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TabStampWeb
     */
    'logoBase64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TabStampWeb
     */
    'transparent'?: number;
    /**
     * 
     * @type {StampLocation}
     * @memberof TabStampWeb
     */
    'location'?: StampLocation;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'showStamp'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'automaticCount'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'autoScale'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TabStampWeb
     */
    'showFromTOC'?: boolean;
}
/**
 * 
 * @export
 * @interface TagCategory
 */
export interface TagCategory {
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'keywordDa'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategory
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagCategory
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface TagCategoryListApiResult
 */
export interface TagCategoryListApiResult {
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof TagCategoryListApiResult
     */
    'data'?: Array<TagCategory> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagCategoryListApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagCategoryListApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagCategoryListApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface TagData
 */
export interface TagData {
    /**
     * 
     * @type {Array<TagCategory>}
     * @memberof TagData
     */
    'tagCategories'?: Array<TagCategory> | null;
    /**
     * 
     * @type {Array<TagName>}
     * @memberof TagData
     */
    'tagNames'?: Array<TagName> | null;
    /**
     * 
     * @type {Array<TagInfo>}
     * @memberof TagData
     */
    'tagInfos'?: Array<TagInfo> | null;
}
/**
 * 
 * @export
 * @interface TagInfo
 */
export interface TagInfo {
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'tagNameId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'keyWord'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'backColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'foreColor'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagInfo
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagInfo
     */
    'fontName'?: string | null;
}
/**
 * 
 * @export
 * @interface TagName
 */
export interface TagName {
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'tagCategoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'keyword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagName
     */
    'keywordDa'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagName
     */
    'isDefault'?: boolean;
}
/**
 * 
 * @export
 * @interface TagNameListApiResult
 */
export interface TagNameListApiResult {
    /**
     * 
     * @type {Array<TagName>}
     * @memberof TagNameListApiResult
     */
    'data'?: Array<TagName> | null;
    /**
     * 
     * @type {boolean}
     * @memberof TagNameListApiResult
     */
    'error'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TagNameListApiResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TagNameListApiResult
     */
    'errorType'?: string | null;
}
/**
 * 
 * @export
 * @interface TemplateItem
 */
export interface TemplateItem {
    /**
     * 
     * @type {string}
     * @memberof TemplateItem
     */
    'templateKey'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateItem
     */
    'checked'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TemplateItem
     */
    'isFull'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TemplateItem
     */
    'value'?: string | null;
}
/**
 * 
 * @export
 * @interface ThumbnailResult
 */
export interface ThumbnailResult {
    /**
     * 
     * @type {string}
     * @memberof ThumbnailResult
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {Array<ImageDetail>}
     * @memberof ThumbnailResult
     */
    'imageBases'?: Array<ImageDetail> | null;
    /**
     * 
     * @type {string}
     * @memberof ThumbnailResult
     */
    'tabFileInfoId'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ThumbnailType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type ThumbnailType = typeof ThumbnailType[keyof typeof ThumbnailType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TocColumEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_MINUS_1: -1
} as const;

export type TocColumEnum = typeof TocColumEnum[keyof typeof TocColumEnum];


/**
 * 
 * @export
 * @interface TocRow
 */
export interface TocRow {
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'indexView'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'index'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'appendix'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'startPage'?: number;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'page'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'height'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TocRow
     */
    'isTocPageBreak'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'uniqueId'?: string;
    /**
     * 
     * @type {string}
     * @memberof TocRow
     */
    'parentId'?: string;
    /**
     * 
     * @type {number}
     * @memberof TocRow
     */
    'level'?: number;
}
/**
 * 
 * @export
 * @interface TocSettings
 */
export interface TocSettings {
    /**
     * 
     * @type {string}
     * @memberof TocSettings
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'titleFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'headerFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'documentFontSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TocSettings
     */
    'fontSizeDecreasesEachLevel'?: number;
}
/**
 * 
 * @export
 * @interface UnSelectedAllSelectAllRowParam
 */
export interface UnSelectedAllSelectAllRowParam {
    /**
     * 
     * @type {WatchDogParamWeb}
     * @memberof UnSelectedAllSelectAllRowParam
     */
    'param'?: WatchDogParamWeb;
    /**
     * 
     * @type {Array<WatchDogObj>}
     * @memberof UnSelectedAllSelectAllRowParam
     */
    'watchDogObjs'?: Array<WatchDogObj> | null;
}
/**
 * 
 * @export
 * @interface UndoDeleteReplaceFromWord
 */
export interface UndoDeleteReplaceFromWord {
    /**
     * 
     * @type {Array<RectangleSelection>}
     * @memberof UndoDeleteReplaceFromWord
     */
    'rectangleSelectionOfCharacters'?: Array<RectangleSelection> | null;
    /**
     * 
     * @type {ReplaceObj}
     * @memberof UndoDeleteReplaceFromWord
     */
    'replaceObj'?: ReplaceObj;
}
/**
 * 
 * @export
 * @interface UndoRemoveReplaceBox
 */
export interface UndoRemoveReplaceBox {
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'left'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'top'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'replaceBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'fontColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'extractColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'fontName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UndoRemoveReplaceBox
     */
    'fontSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof UndoRemoveReplaceBox
     */
    'replaceObjId'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateLineParam
 */
export interface UpdateLineParam {
    /**
     * 
     * @type {string}
     * @memberof UpdateLineParam
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLineParam
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLineParam
     */
    'borderWidth'?: number;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'address'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'companyId'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'licenseCompanyId'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfo
     */
    'role'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'licenseKey'?: string | null;
}
/**
 * 
 * @export
 * @interface UserStatusResult
 */
export interface UserStatusResult {
    /**
     * 
     * @type {RowStatus}
     * @memberof UserStatusResult
     */
    'status'?: RowStatus;
    /**
     * 
     * @type {LicenseReponseResult}
     * @memberof UserStatusResult
     */
    'licenseData'?: LicenseReponseResult;
}


/**
 * 
 * @export
 * @interface UserStatusResultOperationResult
 */
export interface UserStatusResultOperationResult {
    /**
     * 
     * @type {UserStatusResult}
     * @memberof UserStatusResultOperationResult
     */
    'result'?: UserStatusResult;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isSuccess'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserStatusResultOperationResult
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isException'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserStatusResultOperationResult
     */
    'isNotFound'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ViewerZoomMode = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ViewerZoomMode = typeof ViewerZoomMode[keyof typeof ViewerZoomMode];


/**
 * 
 * @export
 * @interface WatchDogObj
 */
export interface WatchDogObj {
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'searchTypePath'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'word'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'selectedCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'replacePercent'?: number;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'totalCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'lastSelectedTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof WatchDogObj
     */
    'highlightOnPublishCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'searchTypeKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WatchDogObj
     */
    'tagId'?: string | null;
}
/**
 * 
 * @export
 * @interface WatchDogParamWeb
 */
export interface WatchDogParamWeb {
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'wholeWord'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'matchCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof WatchDogParamWeb
     */
    'combine'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WatchDogParamWeb
     */
    'orderBy'?: string | null;
}
/**
 * 
 * @export
 * @interface WordInPage
 */
export interface WordInPage {
    /**
     * 
     * @type {number}
     * @memberof WordInPage
     */
    'pageNo'?: number;
    /**
     * 
     * @type {Array<LxWordResponse>}
     * @memberof WordInPage
     */
    'words'?: Array<LxWordResponse> | null;
    /**
     * 
     * @type {Array<LxChar>}
     * @memberof WordInPage
     */
    'charHightLight'?: Array<LxChar> | null;
}

/**
 * ApprovalStampApi - axios parameter creator
 * @export
 */
export const ApprovalStampApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddApprovalStampToPagePost: async (pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddApprovalStampToPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalStampInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetApprovalStampImageParam} [getApprovalStampImageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampImagePost: async (getApprovalStampImageParam?: GetApprovalStampImageParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetApprovalStampImage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getApprovalStampImageParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampTemplaceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetApprovalStampTemplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListApprovalStampGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListApprovalStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveApprovalStampDelete: async (pageNo?: number, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveApprovalStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveEditedStampPost: async (pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveEditedStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalStampInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApprovalStampApi - functional programming interface
 * @export
 */
export const ApprovalStampApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApprovalStampApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddApprovalStampToPagePost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampViewItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddApprovalStampToPagePost(pageNo, approvalStampInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetApprovalStampImageParam} [getApprovalStampImageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam?: GetApprovalStampImageParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetApprovalStampTemplaceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampTemplateWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetApprovalStampTemplaceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListApprovalStampGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampViewItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListApprovalStampGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveApprovalStampDelete(pageNo?: number, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampViewItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveApprovalStampDelete(pageNo, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveEditedStampPost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampViewItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveEditedStampPost(pageNo, approvalStampInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApprovalStampApi - factory interface
 * @export
 */
export const ApprovalStampApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApprovalStampApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddApprovalStampToPagePost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: any): AxiosPromise<Array<ApprovalStampViewItem>> {
            return localVarFp.apiLegalxtractAddApprovalStampToPagePost(pageNo, approvalStampInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetApprovalStampImageParam} [getApprovalStampImageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam?: GetApprovalStampImageParam, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampTemplaceGet(options?: any): AxiosPromise<Array<ApprovalStampTemplateWeb>> {
            return localVarFp.apiLegalxtractGetApprovalStampTemplaceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListApprovalStampGet(options?: any): AxiosPromise<Array<ApprovalStampViewItem>> {
            return localVarFp.apiLegalxtractGetListApprovalStampGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveApprovalStampDelete(pageNo?: number, id?: string, options?: any): AxiosPromise<Array<ApprovalStampViewItem>> {
            return localVarFp.apiLegalxtractRemoveApprovalStampDelete(pageNo, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNo] 
         * @param {ApprovalStampInfo} [approvalStampInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveEditedStampPost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: any): AxiosPromise<Array<ApprovalStampViewItem>> {
            return localVarFp.apiLegalxtractSaveEditedStampPost(pageNo, approvalStampInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApprovalStampApi - object-oriented interface
 * @export
 * @class ApprovalStampApi
 * @extends {BaseAPI}
 */
export class ApprovalStampApi extends BaseAPI {
    /**
     * 
     * @param {number} [pageNo] 
     * @param {ApprovalStampInfo} [approvalStampInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractAddApprovalStampToPagePost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractAddApprovalStampToPagePost(pageNo, approvalStampInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetApprovalStampImageParam} [getApprovalStampImageParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam?: GetApprovalStampImageParam, options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractGetApprovalStampImagePost(getApprovalStampImageParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractGetApprovalStampTemplaceGet(options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractGetApprovalStampTemplaceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractGetListApprovalStampGet(options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractGetListApprovalStampGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNo] 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractRemoveApprovalStampDelete(pageNo?: number, id?: string, options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractRemoveApprovalStampDelete(pageNo, id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNo] 
     * @param {ApprovalStampInfo} [approvalStampInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampApi
     */
    public apiLegalxtractSaveEditedStampPost(pageNo?: number, approvalStampInfo?: ApprovalStampInfo, options?: AxiosRequestConfig) {
        return ApprovalStampApiFp(this.configuration).apiLegalxtractSaveEditedStampPost(pageNo, approvalStampInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApprovalStampTemplateApi - axios parameter creator
 * @export
 */
export const ApprovalStampTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseApprovalStampTemplateGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseApprovalStampTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDesignApprovalStampTemplatePost: async (templateId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseDesignApprovalStampTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (templateId !== undefined) {
                localVarQueryParameter['templateId'] = templateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportApprovalStampTemplatePost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExportApprovalStampTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Int32LegalManagerParam} [int32LegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampTemplatesPost: async (int32LegalManagerParam?: Int32LegalManagerParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetApprovalStampTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(int32LegalManagerParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenFileTemplatePost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/OpenFileTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApprovalStampTemplateListLegalManagerParam} [approvalStampTemplateListLegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveApprovalStampTemplatePost: async (approvalStampTemplateListLegalManagerParam?: ApprovalStampTemplateListLegalManagerParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveApprovalStampTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalStampTemplateListLegalManagerParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApprovalStampTemplate} [approvalStampTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadTemplatePost: async (approvalStampTemplate?: ApprovalStampTemplate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UploadTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalStampTemplate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApprovalStampTemplateApi - functional programming interface
 * @export
 */
export const ApprovalStampTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApprovalStampTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseApprovalStampTemplateGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseApprovalStampTemplateGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExportApprovalStampTemplatePost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExportApprovalStampTemplatePost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Int32LegalManagerParam} [int32LegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam?: Int32LegalManagerParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalStampTemplateWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractOpenFileTemplatePost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractOpenFileTemplatePost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApprovalStampTemplateListLegalManagerParam} [approvalStampTemplateListLegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam?: ApprovalStampTemplateListLegalManagerParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApprovalStampTemplate} [approvalStampTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUploadTemplatePost(approvalStampTemplate?: ApprovalStampTemplate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUploadTemplatePost(approvalStampTemplate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApprovalStampTemplateApi - factory interface
 * @export
 */
export const ApprovalStampTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApprovalStampTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseApprovalStampTemplateGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractCloseApprovalStampTemplateGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [templateId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportApprovalStampTemplatePost(requestBody?: Array<string>, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExportApprovalStampTemplatePost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Int32LegalManagerParam} [int32LegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam?: Int32LegalManagerParam, options?: any): AxiosPromise<Array<ApprovalStampTemplateWeb>> {
            return localVarFp.apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenFileTemplatePost(file?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractOpenFileTemplatePost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApprovalStampTemplateListLegalManagerParam} [approvalStampTemplateListLegalManagerParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam?: ApprovalStampTemplateListLegalManagerParam, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApprovalStampTemplate} [approvalStampTemplate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadTemplatePost(approvalStampTemplate?: ApprovalStampTemplate, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractUploadTemplatePost(approvalStampTemplate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApprovalStampTemplateApi - object-oriented interface
 * @export
 * @class ApprovalStampTemplateApi
 * @extends {BaseAPI}
 */
export class ApprovalStampTemplateApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractCloseApprovalStampTemplateGet(options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractCloseApprovalStampTemplateGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [templateId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId?: string, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractCloseDesignApprovalStampTemplatePost(templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractExportApprovalStampTemplatePost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractExportApprovalStampTemplatePost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Int32LegalManagerParam} [int32LegalManagerParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam?: Int32LegalManagerParam, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractGetApprovalStampTemplatesPost(int32LegalManagerParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractOpenFileTemplatePost(file?: File, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractOpenFileTemplatePost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApprovalStampTemplateListLegalManagerParam} [approvalStampTemplateListLegalManagerParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam?: ApprovalStampTemplateListLegalManagerParam, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractSaveApprovalStampTemplatePost(approvalStampTemplateListLegalManagerParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApprovalStampTemplate} [approvalStampTemplate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStampTemplateApi
     */
    public apiLegalxtractUploadTemplatePost(approvalStampTemplate?: ApprovalStampTemplate, options?: AxiosRequestConfig) {
        return ApprovalStampTemplateApiFp(this.configuration).apiLegalxtractUploadTemplatePost(approvalStampTemplate, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlankFileApi - axios parameter creator
 * @export
 */
export const BlankFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCreateBlankFilePost: async (createBlankFileParam?: CreateBlankFileParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CreateBlankFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBlankFileParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlankFileApi - functional programming interface
 * @export
 */
export const BlankFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlankFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReOrderTOCResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCreateBlankFilePost(createBlankFileParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlankFileApi - factory interface
 * @export
 */
export const BlankFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlankFileApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBlankFileParam} [createBlankFileParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: any): AxiosPromise<ReOrderTOCResponse> {
            return localVarFp.apiLegalxtractCreateBlankFilePost(createBlankFileParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlankFileApi - object-oriented interface
 * @export
 * @class BlankFileApi
 * @extends {BaseAPI}
 */
export class BlankFileApi extends BaseAPI {
    /**
     * 
     * @param {CreateBlankFileParam} [createBlankFileParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlankFileApi
     */
    public apiLegalxtractCreateBlankFilePost(createBlankFileParam?: CreateBlankFileParam, options?: AxiosRequestConfig) {
        return BlankFileApiFp(this.configuration).apiLegalxtractCreateBlankFilePost(createBlankFileParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BlankPageApi - axios parameter creator
 * @export
 */
export const BlankPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý thêm BlankPage
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessBlankPageGet: async (isShowBlankPage?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessBlankPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isShowBlankPage !== undefined) {
                localVarQueryParameter['isShowBlankPage'] = isShowBlankPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BlankPageApi - functional programming interface
 * @export
 */
export const BlankPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BlankPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm BlankPage
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessBlankPageGet(isShowBlankPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BlankPageApi - factory interface
 * @export
 */
export const BlankPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BlankPageApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm BlankPage
         * @param {boolean} [isShowBlankPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractProcessBlankPageGet(isShowBlankPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BlankPageApi - object-oriented interface
 * @export
 * @class BlankPageApi
 * @extends {BaseAPI}
 */
export class BlankPageApi extends BaseAPI {
    /**
     * 
     * @summary xử lý thêm BlankPage
     * @param {boolean} [isShowBlankPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlankPageApi
     */
    public apiLegalxtractProcessBlankPageGet(isShowBlankPage?: boolean, options?: AxiosRequestConfig) {
        return BlankPageApiFp(this.configuration).apiLegalxtractProcessBlankPageGet(isShowBlankPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BookMarkApi - axios parameter creator
 * @export
 */
export const BookMarkApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddPageToBookMarkPut: async (pageId?: string, bookmarkObj?: BookmarkObj, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddPageToBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageId !== undefined) {
                localVarQueryParameter['pageId'] = pageId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookmarkObj, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteBookMarkDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetAllBookmarkObjsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetAllBookmarkObjs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateBookMarkPut: async (bookmarkId?: string, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UpdateBookMark`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bookmarkId !== undefined) {
                localVarQueryParameter['bookmarkId'] = bookmarkId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BookMarkApi - functional programming interface
 * @export
 */
export const BookMarkApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BookMarkApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteBookMarkDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Page>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteBookMarkDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetAllBookmarkObjsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookmarkObj>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetAllBookmarkObjsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BookMarkApi - factory interface
 * @export
 */
export const BookMarkApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BookMarkApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [pageId] 
         * @param {BookmarkObj} [bookmarkObj] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: any): AxiosPromise<Page> {
            return localVarFp.apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteBookMarkDelete(id?: string, options?: any): AxiosPromise<Page> {
            return localVarFp.apiLegalxtractDeleteBookMarkDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetAllBookmarkObjsGet(options?: any): AxiosPromise<Array<BookmarkObj>> {
            return localVarFp.apiLegalxtractGetAllBookmarkObjsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bookmarkId] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BookMarkApi - object-oriented interface
 * @export
 * @class BookMarkApi
 * @extends {BaseAPI}
 */
export class BookMarkApi extends BaseAPI {
    /**
     * 
     * @param {string} [pageId] 
     * @param {BookmarkObj} [bookmarkObj] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractAddPageToBookMarkPut(pageId?: string, bookmarkObj?: BookmarkObj, options?: AxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractAddPageToBookMarkPut(pageId, bookmarkObj, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractDeleteBookMarkDelete(id?: string, options?: AxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractDeleteBookMarkDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractGetAllBookmarkObjsGet(options?: AxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractGetAllBookmarkObjsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bookmarkId] 
     * @param {string} [title] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BookMarkApi
     */
    public apiLegalxtractUpdateBookMarkPut(bookmarkId?: string, title?: string, options?: AxiosRequestConfig) {
        return BookMarkApiFp(this.configuration).apiLegalxtractUpdateBookMarkPut(bookmarkId, title, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BoxApi - axios parameter creator
 * @export
 */
export const BoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý thêm Box
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxPost: async (viewerPageNo?: number, addBoxParam?: AddBoxParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractConvertReplaceBoxToBoxPost: async (viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ConvertReplaceBoxToBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xóa Box
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveBoxPost: async (boxId?: string, viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BoxApi - functional programming interface
 * @export
 */
export const BoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm Box
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObj>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObj>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo, replaceBoxParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xóa Box
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddBoxParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BoxApi - factory interface
 * @export
 */
export const BoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BoxApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm Box
         * @param {number} [viewerPageNo] 
         * @param {AddBoxParam} [addBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: any): AxiosPromise<BoxObj> {
            return localVarFp.apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: any): AxiosPromise<BoxObj> {
            return localVarFp.apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo, replaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xóa Box
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: any): AxiosPromise<AddBoxParam> {
            return localVarFp.apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BoxApi - object-oriented interface
 * @export
 * @class BoxApi
 * @extends {BaseAPI}
 */
export class BoxApi extends BaseAPI {
    /**
     * 
     * @summary xử lý thêm Box
     * @param {number} [viewerPageNo] 
     * @param {AddBoxParam} [addBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractAddBoxPost(viewerPageNo?: number, addBoxParam?: AddBoxParam, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractAddBoxPost(viewerPageNo, addBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {ReplaceBoxParam} [replaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractConvertReplaceBoxToBoxPost(viewerPageNo, replaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xóa Box
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BoxApi
     */
    public apiLegalxtractRemoveBoxPost(boxId?: string, viewerPageNo?: number, options?: AxiosRequestConfig) {
        return BoxApiFp(this.configuration).apiLegalxtractRemoveBoxPost(boxId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CheckDuplicateApi - axios parameter creator
 * @export
 */
export const CheckDuplicateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStateForPageDuplicatePost: async (pageStatus?: Array<PageStatus>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyStateForPageDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageStatus, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckDuplicatePost: async (checkDuplicateSettings?: CheckDuplicateSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkDuplicateSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDuplicateGet: async (sesionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sesionId !== undefined) {
                localVarQueryParameter['sesionId'] = sesionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowDuplicateGet: async (sesionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowDuplicate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sesionId !== undefined) {
                localVarQueryParameter['sesionId'] = sesionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckDuplicateApi - functional programming interface
 * @export
 */
export const CheckDuplicateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckDuplicateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckDuplicateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseDuplicateGet(sesionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseDuplicateGet(sesionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowDuplicateGet(sesionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuViewareRESTOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowDuplicateGet(sesionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckDuplicateApi - factory interface
 * @export
 */
export const CheckDuplicateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckDuplicateApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<PageStatus>} [pageStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: any): AxiosPromise<CheckDuplicateResponse> {
            return localVarFp.apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseDuplicateGet(sesionId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractCloseDuplicateGet(sesionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sesionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowDuplicateGet(sesionId?: string, options?: any): AxiosPromise<DocuViewareRESTOutputResponse> {
            return localVarFp.apiLegalxtractShowDuplicateGet(sesionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckDuplicateApi - object-oriented interface
 * @export
 * @class CheckDuplicateApi
 * @extends {BaseAPI}
 */
export class CheckDuplicateApi extends BaseAPI {
    /**
     * 
     * @param {Array<PageStatus>} [pageStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractApplyStateForPageDuplicatePost(pageStatus?: Array<PageStatus>, options?: AxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractApplyStateForPageDuplicatePost(pageStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckDuplicateSettings} [checkDuplicateSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractCheckDuplicatePost(checkDuplicateSettings?: CheckDuplicateSettings, options?: AxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractCheckDuplicatePost(checkDuplicateSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sesionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractCloseDuplicateGet(sesionId?: string, options?: AxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractCloseDuplicateGet(sesionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sesionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckDuplicateApi
     */
    public apiLegalxtractShowDuplicateGet(sesionId?: string, options?: AxiosRequestConfig) {
        return CheckDuplicateApiFp(this.configuration).apiLegalxtractShowDuplicateGet(sesionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxCommentPut: async (boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddBoxComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplyCommentPut: async (parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplyComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTextCommentPut: async (x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTextComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (x !== undefined) {
                localVarQueryParameter['x'] = x;
            }

            if (y !== undefined) {
                localVarQueryParameter['y'] = y;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteCommentDelete: async (id?: string, viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditCommentPost: async (viewerPageNo?: number, commentParam?: CommentParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListCommentGet: async (commentOrderBy?: CommentOrderByEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (commentOrderBy !== undefined) {
                localVarQueryParameter['commentOrderBy'] = commentOrderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPrintCommentGet: async (fileName?: string, orderBy?: CommentOrderByEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/PrintComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentObjDisplayWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentObjDisplayWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListCommentGet(commentOrderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractPrintCommentGet(fileName, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWeb> {
            return localVarFp.apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentId] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWeb> {
            return localVarFp.apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [x] 
         * @param {number} [y] 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWeb> {
            return localVarFp.apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {CommentParam} [commentParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: any): AxiosPromise<CommentObjDisplayWeb> {
            return localVarFp.apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CommentOrderByEnum} [commentOrderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: any): AxiosPromise<Array<CommentObjDisplayWeb>> {
            return localVarFp.apiLegalxtractGetListCommentGet(commentOrderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {CommentOrderByEnum} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractPrintCommentGet(fileName, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddBoxCommentPut(boxId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddBoxCommentPut(boxId, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentId] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddReplyCommentPut(parentId?: string, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddReplyCommentPut(parentId, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [x] 
     * @param {number} [y] 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractAddTextCommentPut(x?: number, y?: number, viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractAddTextCommentPut(x, y, viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractDeleteCommentDelete(id?: string, viewerPageNo?: number, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractDeleteCommentDelete(id, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {CommentParam} [commentParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractEditCommentPost(viewerPageNo?: number, commentParam?: CommentParam, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractEditCommentPost(viewerPageNo, commentParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CommentOrderByEnum} [commentOrderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractGetListCommentGet(commentOrderBy?: CommentOrderByEnum, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractGetListCommentGet(commentOrderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {CommentOrderByEnum} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public apiLegalxtractPrintCommentGet(fileName?: string, orderBy?: CommentOrderByEnum, options?: AxiosRequestConfig) {
        return CommentApiFp(this.configuration).apiLegalxtractPrintCommentGet(fileName, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeskewApi - axios parameter creator
 * @export
 */
export const DeskewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeskewPost: async (requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/Deskew`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeskewApi - functional programming interface
 * @export
 */
export const DeskewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeskewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeskewPost(requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeskewPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeskewApi - factory interface
 * @export
 */
export const DeskewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeskewApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeskewPost(requestBody?: Array<number>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeskewPost(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeskewApi - object-oriented interface
 * @export
 * @class DeskewApi
 * @extends {BaseAPI}
 */
export class DeskewApi extends BaseAPI {
    /**
     * 
     * @param {Array<number>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeskewApi
     */
    public apiLegalxtractDeskewPost(requestBody?: Array<number>, options?: AxiosRequestConfig) {
        return DeskewApiFp(this.configuration).apiLegalxtractDeskewPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocuViewareApi - axios parameter creator
 * @export
 */
export const DocuViewareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClearAllCacheGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ClearAllCache`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocuviewareControlGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDocuviewareControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInitDocuViewareControlGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/InitDocuViewareControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ViewerZoomMode} zoomMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetZoomModeZoomModeGet: async (zoomMode: ViewerZoomMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoomMode' is not null or undefined
            assertParamExists('apiLegalxtractSetZoomModeZoomModeGet', 'zoomMode', zoomMode)
            const localVarPath = `/api/legalxtract/SetZoomMode/{zoomMode}`
                .replace(`{${"zoomMode"}}`, encodeURIComponent(String(zoomMode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocuViewareApi - functional programming interface
 * @export
 */
export const DocuViewareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocuViewareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractClearAllCacheGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractClearAllCacheGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDocuviewareControlGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDocuviewareControlGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractInitDocuViewareControlGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractInitDocuViewareControlGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ViewerZoomMode} zoomMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetZoomModeZoomModeGet(zoomMode: ViewerZoomMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetZoomModeZoomModeGet(zoomMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocuViewareApi - factory interface
 * @export
 */
export const DocuViewareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocuViewareApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClearAllCacheGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractClearAllCacheGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocuviewareControlGet(options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractGetDocuviewareControlGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInitDocuViewareControlGet(options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractInitDocuViewareControlGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ViewerZoomMode} zoomMode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetZoomModeZoomModeGet(zoomMode: ViewerZoomMode, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractSetZoomModeZoomModeGet(zoomMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocuViewareApi - object-oriented interface
 * @export
 * @class DocuViewareApi
 * @extends {BaseAPI}
 */
export class DocuViewareApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuViewareApi
     */
    public apiLegalxtractClearAllCacheGet(options?: AxiosRequestConfig) {
        return DocuViewareApiFp(this.configuration).apiLegalxtractClearAllCacheGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuViewareApi
     */
    public apiLegalxtractGetDocuviewareControlGet(options?: AxiosRequestConfig) {
        return DocuViewareApiFp(this.configuration).apiLegalxtractGetDocuviewareControlGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuViewareApi
     */
    public apiLegalxtractInitDocuViewareControlGet(options?: AxiosRequestConfig) {
        return DocuViewareApiFp(this.configuration).apiLegalxtractInitDocuViewareControlGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ViewerZoomMode} zoomMode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuViewareApi
     */
    public apiLegalxtractSetZoomModeZoomModeGet(zoomMode: ViewerZoomMode, options?: AxiosRequestConfig) {
        return DocuViewareApiFp(this.configuration).apiLegalxtractSetZoomModeZoomModeGet(zoomMode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocuVieware3Api - axios parameter creator
 * @export
 */
export const DocuVieware3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3AnnotupdatePost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/annotupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3BaserequestPost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/baserequest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3FormfieldupdatePost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/formfieldupdate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3LoadfromfilePost: async (body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/loadfromfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3LoadfromfilemultipartPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/loadfromfilemultipart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3PingGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {string} [fileName] 
         * @param {string} [format] 
         * @param {string} [pageRange] 
         * @param {boolean} [dropAnnotations] 
         * @param {boolean} [flattenAnnotations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3SaveGet: async (sessionID?: string, fileName?: string, format?: string, pageRange?: string, dropAnnotations?: boolean, flattenAnnotations?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionID !== undefined) {
                localVarQueryParameter['sessionID'] = sessionID;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (pageRange !== undefined) {
                localVarQueryParameter['pageRange'] = pageRange;
            }

            if (dropAnnotations !== undefined) {
                localVarQueryParameter['dropAnnotations'] = dropAnnotations;
            }

            if (flattenAnnotations !== undefined) {
                localVarQueryParameter['flattenAnnotations'] = flattenAnnotations;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3TwainservicesetupdownloadGet: async (sessionID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/docuvieware3/twainservicesetupdownload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sessionID !== undefined) {
                localVarQueryParameter['sessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocuVieware3Api - functional programming interface
 * @export
 */
export const DocuVieware3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocuVieware3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3AnnotupdatePost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3AnnotupdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3BaserequestPost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3BaserequestPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3FormfieldupdatePost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3FormfieldupdatePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3LoadfromfilePost(body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3LoadfromfilePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3LoadfromfilemultipartPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3LoadfromfilemultipartPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3PingGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3PingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {string} [fileName] 
         * @param {string} [format] 
         * @param {string} [pageRange] 
         * @param {boolean} [dropAnnotations] 
         * @param {boolean} [flattenAnnotations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3SaveGet(sessionID?: string, fileName?: string, format?: string, pageRange?: string, dropAnnotations?: boolean, flattenAnnotations?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3SaveGet(sessionID, fileName, format, pageRange, dropAnnotations, flattenAnnotations, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocuvieware3TwainservicesetupdownloadGet(sessionID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocuvieware3TwainservicesetupdownloadGet(sessionID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocuVieware3Api - factory interface
 * @export
 */
export const DocuVieware3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocuVieware3ApiFp(configuration)
    return {
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3AnnotupdatePost(body?: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3AnnotupdatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3BaserequestPost(body?: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3BaserequestPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3FormfieldupdatePost(body?: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3FormfieldupdatePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3LoadfromfilePost(body?: any, options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3LoadfromfilePost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3LoadfromfilemultipartPost(options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3LoadfromfilemultipartPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3PingGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiDocuvieware3PingGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {string} [fileName] 
         * @param {string} [format] 
         * @param {string} [pageRange] 
         * @param {boolean} [dropAnnotations] 
         * @param {boolean} [flattenAnnotations] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3SaveGet(sessionID?: string, fileName?: string, format?: string, pageRange?: string, dropAnnotations?: boolean, flattenAnnotations?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocuvieware3SaveGet(sessionID, fileName, format, pageRange, dropAnnotations, flattenAnnotations, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocuvieware3TwainservicesetupdownloadGet(sessionID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDocuvieware3TwainservicesetupdownloadGet(sessionID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocuVieware3Api - object-oriented interface
 * @export
 * @class DocuVieware3Api
 * @extends {BaseAPI}
 */
export class DocuVieware3Api extends BaseAPI {
    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3AnnotupdatePost(body?: any, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3AnnotupdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3BaserequestPost(body?: any, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3BaserequestPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3FormfieldupdatePost(body?: any, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3FormfieldupdatePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3LoadfromfilePost(body?: any, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3LoadfromfilePost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3LoadfromfilemultipartPost(options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3LoadfromfilemultipartPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3PingGet(options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3PingGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionID] 
     * @param {string} [fileName] 
     * @param {string} [format] 
     * @param {string} [pageRange] 
     * @param {boolean} [dropAnnotations] 
     * @param {boolean} [flattenAnnotations] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3SaveGet(sessionID?: string, fileName?: string, format?: string, pageRange?: string, dropAnnotations?: boolean, flattenAnnotations?: boolean, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3SaveGet(sessionID, fileName, format, pageRange, dropAnnotations, flattenAnnotations, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocuVieware3Api
     */
    public apiDocuvieware3TwainservicesetupdownloadGet(sessionID?: string, options?: AxiosRequestConfig) {
        return DocuVieware3ApiFp(this.configuration).apiDocuvieware3TwainservicesetupdownloadGet(sessionID, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FontApi - axios parameter creator
 * @export
 */
export const FontApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Lấy danh sách tên font trên Server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListFontGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListFont`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FontApi - functional programming interface
 * @export
 */
export const FontApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FontApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Lấy danh sách tên font trên Server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListFontGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListFontGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FontApi - factory interface
 * @export
 */
export const FontApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FontApiFp(configuration)
    return {
        /**
         * 
         * @summary Lấy danh sách tên font trên Server
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListFontGet(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLegalxtractGetListFontGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FontApi - object-oriented interface
 * @export
 * @class FontApi
 * @extends {BaseAPI}
 */
export class FontApi extends BaseAPI {
    /**
     * 
     * @summary Lấy danh sách tên font trên Server
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FontApi
     */
    public apiLegalxtractGetListFontGet(options?: AxiosRequestConfig) {
        return FontApiFp(this.configuration).apiLegalxtractGetListFontGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontPageApi - axios parameter creator
 * @export
 */
export const FrontPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddFrontPageContentPost: async (frontPageContentInfo?: FrontPageContentInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddFrontPageContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontPageContentInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessFrontPagePost: async (addFrontPage?: boolean, file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessFrontPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (addFrontPage !== undefined) {
                localVarQueryParameter['addFrontPage'] = addFrontPage;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAnnotFrontPagePost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAnnotFrontPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontPageApi - functional programming interface
 * @export
 */
export const FrontPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessFrontPagePost(addFrontPage, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAnnotFrontPagePost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontPageApi - factory interface
 * @export
 */
export const FrontPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontPageApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {FrontPageContentInfo} [frontPageContentInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {boolean} [addFrontPage] 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractProcessFrontPagePost(addFrontPage, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xử lý thêm FrontPage
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractRemoveAnnotFrontPagePost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontPageApi - object-oriented interface
 * @export
 * @class FrontPageApi
 * @extends {BaseAPI}
 */
export class FrontPageApi extends BaseAPI {
    /**
     * 
     * @summary xử lý thêm FrontPage
     * @param {FrontPageContentInfo} [frontPageContentInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractAddFrontPageContentPost(frontPageContentInfo?: FrontPageContentInfo, options?: AxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractAddFrontPageContentPost(frontPageContentInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xử lý thêm FrontPage
     * @param {boolean} [addFrontPage] 
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractProcessFrontPagePost(addFrontPage?: boolean, file?: File, options?: AxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractProcessFrontPagePost(addFrontPage, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xử lý thêm FrontPage
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontPageApi
     */
    public apiLegalxtractRemoveAnnotFrontPagePost(id?: string, options?: AxiosRequestConfig) {
        return FrontPageApiFp(this.configuration).apiLegalxtractRemoveAnnotFrontPagePost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HighlightApi - axios parameter creator
 * @export
 */
export const HighlightApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {boolean} [isHotKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightLinePost: async (viewerPageNo?: number, lineNo?: number, isHotKey?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }

            if (lineNo !== undefined) {
                localVarQueryParameter['lineNo'] = lineNo;
            }

            if (isHotKey !== undefined) {
                localVarQueryParameter['isHotKey'] = isHotKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightByMaskPost: async (mode?: number, rectangleSelection?: Array<RectangleSelection>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightOrUnHighLightByMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rectangleSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Đánh dấu 1 chữ hoặc nhiều chữ.
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightSectionPost: async (mode?: number, rectangleSelection?: Array<RectangleSelection>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightOrUnHighLightSection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rectangleSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightPagePost: async (viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/HighLightPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUnHighLightLinePost: async (viewerPageNo?: number, lineNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UnHighLightLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }

            if (lineNo !== undefined) {
                localVarQueryParameter['lineNo'] = lineNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUnHighLightPagePost: async (viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UnHighLightPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HighlightApi - functional programming interface
 * @export
 */
export const HighlightApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HighlightApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {boolean} [isHotKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightLinePost(viewerPageNo?: number, lineNo?: number, isHotKey?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightLinePost(viewerPageNo, lineNo, isHotKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightOrUnHighLightByMaskPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightOrUnHighLightByMaskPost(mode, rectangleSelection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Đánh dấu 1 chữ hoặc nhiều chữ.
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightOrUnHighLightSectionPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightOrUnHighLightSectionPost(mode, rectangleSelection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHighLightPagePost(viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHighLightPagePost(viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUnHighLightLinePost(viewerPageNo?: number, lineNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUnHighLightLinePost(viewerPageNo, lineNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUnHighLightPagePost(viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUnHighLightPagePost(viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HighlightApi - factory interface
 * @export
 */
export const HighlightApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HighlightApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {boolean} [isHotKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightLinePost(viewerPageNo?: number, lineNo?: number, isHotKey?: boolean, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractHighLightLinePost(viewerPageNo, lineNo, isHotKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightByMaskPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractHighLightOrUnHighLightByMaskPost(mode, rectangleSelection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Đánh dấu 1 chữ hoặc nhiều chữ.
         * @param {number} [mode] 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightOrUnHighLightSectionPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractHighLightOrUnHighLightSectionPost(mode, rectangleSelection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHighLightPagePost(viewerPageNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractHighLightPagePost(viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {number} [lineNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUnHighLightLinePost(viewerPageNo?: number, lineNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractUnHighLightLinePost(viewerPageNo, lineNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUnHighLightPagePost(viewerPageNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractUnHighLightPagePost(viewerPageNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HighlightApi - object-oriented interface
 * @export
 * @class HighlightApi
 * @extends {BaseAPI}
 */
export class HighlightApi extends BaseAPI {
    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {number} [lineNo] 
     * @param {boolean} [isHotKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightLinePost(viewerPageNo?: number, lineNo?: number, isHotKey?: boolean, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightLinePost(viewerPageNo, lineNo, isHotKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [mode] 
     * @param {Array<RectangleSelection>} [rectangleSelection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightOrUnHighLightByMaskPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightOrUnHighLightByMaskPost(mode, rectangleSelection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Đánh dấu 1 chữ hoặc nhiều chữ.
     * @param {number} [mode] 
     * @param {Array<RectangleSelection>} [rectangleSelection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightOrUnHighLightSectionPost(mode?: number, rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightOrUnHighLightSectionPost(mode, rectangleSelection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractHighLightPagePost(viewerPageNo?: number, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractHighLightPagePost(viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {number} [lineNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractUnHighLightLinePost(viewerPageNo?: number, lineNo?: number, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractUnHighLightLinePost(viewerPageNo, lineNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HighlightApi
     */
    public apiLegalxtractUnHighLightPagePost(viewerPageNo?: number, options?: AxiosRequestConfig) {
        return HighlightApiFp(this.configuration).apiLegalxtractUnHighLightPagePost(viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HistoryApi - axios parameter creator
 * @export
 */
export const HistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportExcelGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExportExcel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataOfHistoryFileGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDataOfHistoryFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoryApi - functional programming interface
 * @export
 */
export const HistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExportExcelGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExportExcelGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDataOfHistoryFileGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDataOfHistoryFileGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoryApi - factory interface
 * @export
 */
export const HistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportExcelGet(options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExportExcelGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataOfHistoryFileGet(id?: string, options?: any): AxiosPromise<HistoryView> {
            return localVarFp.apiLegalxtractGetDataOfHistoryFileGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoryApi - object-oriented interface
 * @export
 * @class HistoryApi
 * @extends {BaseAPI}
 */
export class HistoryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public apiLegalxtractExportExcelGet(options?: AxiosRequestConfig) {
        return HistoryApiFp(this.configuration).apiLegalxtractExportExcelGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoryApi
     */
    public apiLegalxtractGetDataOfHistoryFileGet(id?: string, options?: AxiosRequestConfig) {
        return HistoryApiFp(this.configuration).apiLegalxtractGetDataOfHistoryFileGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JoinDocumentApi - axios parameter creator
 * @export
 */
export const JoinDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractJoinDocumentsPost: async (joinDocumentsParam?: JoinDocumentsParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/JoinDocuments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(joinDocumentsParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JoinDocumentApi - functional programming interface
 * @export
 */
export const JoinDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JoinDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JoinDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JoinDocumentApi - factory interface
 * @export
 */
export const JoinDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JoinDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {JoinDocumentsParam} [joinDocumentsParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: any): AxiosPromise<JoinDocumentsResponse> {
            return localVarFp.apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JoinDocumentApi - object-oriented interface
 * @export
 * @class JoinDocumentApi
 * @extends {BaseAPI}
 */
export class JoinDocumentApi extends BaseAPI {
    /**
     * 
     * @param {JoinDocumentsParam} [joinDocumentsParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JoinDocumentApi
     */
    public apiLegalxtractJoinDocumentsPost(joinDocumentsParam?: JoinDocumentsParam, options?: AxiosRequestConfig) {
        return JoinDocumentApiFp(this.configuration).apiLegalxtractJoinDocumentsPost(joinDocumentsParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeLanguagesLanguageGet: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiLegalxtractChangeLanguagesLanguageGet', 'language', language)
            const localVarPath = `/api/legalxtract/ChangeLanguages/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetLanguagesLanguageGet: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('apiLegalxtractGetLanguagesLanguageGet', 'language', language)
            const localVarPath = `/api/legalxtract/GetLanguages/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetLanguageGet: async (language?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SetLanguage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractChangeLanguagesLanguageGet(language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLanguagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractChangeLanguagesLanguageGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetLanguagesLanguageGet(language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetLanguagesLanguageGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetLanguageGet(language?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetLanguageGet(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeLanguagesLanguageGet(language: string, options?: any): AxiosPromise<GetLanguagesResponse> {
            return localVarFp.apiLegalxtractChangeLanguagesLanguageGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetLanguagesLanguageGet(language: string, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiLegalxtractGetLanguagesLanguageGet(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [language] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetLanguageGet(language?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractSetLanguageGet(language, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLegalxtractChangeLanguagesLanguageGet(language: string, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLegalxtractChangeLanguagesLanguageGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLegalxtractGetLanguagesLanguageGet(language: string, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLegalxtractGetLanguagesLanguageGet(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [language] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public apiLegalxtractSetLanguageGet(language?: string, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).apiLegalxtractSetLanguageGet(language, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LegalApi - axios parameter creator
 * @export
 */
export const LegalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {LegalParam} [legalParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLXTGet: async (legalParam?: LegalParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LXT`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(legalParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LegalApi - functional programming interface
 * @export
 */
export const LegalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LegalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {LegalParam} [legalParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLXTGet(legalParam?: LegalParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLXTGet(legalParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LegalApi - factory interface
 * @export
 */
export const LegalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LegalApiFp(configuration)
    return {
        /**
         * 
         * @param {LegalParam} [legalParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLXTGet(legalParam?: LegalParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractLXTGet(legalParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LegalApi - object-oriented interface
 * @export
 * @class LegalApi
 * @extends {BaseAPI}
 */
export class LegalApi extends BaseAPI {
    /**
     * 
     * @param {LegalParam} [legalParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LegalApi
     */
    public apiLegalxtractLXTGet(legalParam?: LegalParam, options?: AxiosRequestConfig) {
        return LegalApiFp(this.configuration).apiLegalxtractLXTGet(legalParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary get thông tin license
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadLicenseInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoadLicenseInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LicenseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary get thông tin license
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoadLicenseInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LicenseInfoWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoadLicenseInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LicenseApiFp(configuration)
    return {
        /**
         * 
         * @summary get thông tin license
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadLicenseInfoGet(options?: any): AxiosPromise<LicenseInfoWeb> {
            return localVarFp.apiLegalxtractLoadLicenseInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * 
     * @summary get thông tin license
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public apiLegalxtractLoadLicenseInfoGet(options?: AxiosRequestConfig) {
        return LicenseApiFp(this.configuration).apiLegalxtractLoadLicenseInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LineApi - axios parameter creator
 * @export
 */
export const LineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý thêm Line
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddLinePost: async (viewerPageNo?: number, addLineParam?: AddLineParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addLineParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update Line
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveLinePost: async (id?: string, viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary update Line
         * @param {number} [viewerPageNo] 
         * @param {UpdateLineParam} [updateLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateLinePost: async (viewerPageNo?: number, updateLineParam?: UpdateLineParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UpdateLine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLineParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LineApi - functional programming interface
 * @export
 */
export const LineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm Line
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update Line
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddLineParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveLinePost(id, viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary update Line
         * @param {number} [viewerPageNo] 
         * @param {UpdateLineParam} [updateLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUpdateLinePost(viewerPageNo?: number, updateLineParam?: UpdateLineParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateLineParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUpdateLinePost(viewerPageNo, updateLineParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LineApi - factory interface
 * @export
 */
export const LineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LineApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý thêm Line
         * @param {number} [viewerPageNo] 
         * @param {AddLineParam} [addLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update Line
         * @param {string} [id] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: any): AxiosPromise<AddLineParam> {
            return localVarFp.apiLegalxtractRemoveLinePost(id, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary update Line
         * @param {number} [viewerPageNo] 
         * @param {UpdateLineParam} [updateLineParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUpdateLinePost(viewerPageNo?: number, updateLineParam?: UpdateLineParam, options?: any): AxiosPromise<UpdateLineParam> {
            return localVarFp.apiLegalxtractUpdateLinePost(viewerPageNo, updateLineParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LineApi - object-oriented interface
 * @export
 * @class LineApi
 * @extends {BaseAPI}
 */
export class LineApi extends BaseAPI {
    /**
     * 
     * @summary xử lý thêm Line
     * @param {number} [viewerPageNo] 
     * @param {AddLineParam} [addLineParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractAddLinePost(viewerPageNo?: number, addLineParam?: AddLineParam, options?: AxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractAddLinePost(viewerPageNo, addLineParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update Line
     * @param {string} [id] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractRemoveLinePost(id?: string, viewerPageNo?: number, options?: AxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractRemoveLinePost(id, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary update Line
     * @param {number} [viewerPageNo] 
     * @param {UpdateLineParam} [updateLineParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LineApi
     */
    public apiLegalxtractUpdateLinePost(viewerPageNo?: number, updateLineParam?: UpdateLineParam, options?: AxiosRequestConfig) {
        return LineApiFp(this.configuration).apiLegalxtractUpdateLinePost(viewerPageNo, updateLineParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangePasswordPost: async (changePassWordParam?: ChangePassWordParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ChangePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePassWordParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckUserStatusPost: async (checkUserStatusParam?: CheckUserStatusParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckUserStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkUserStatusParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoginAppPost: async (loginAppParam?: LoginAppParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoginApp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginAppParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLogoutPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractValidateNovaTokenPost: async (loginNovaParam?: LoginNovaParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ValidateNovaToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginNovaParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractChangePasswordPost(changePassWordParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserStatusResultOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoginAppPost(loginAppParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLogoutPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLogoutPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractValidateNovaTokenPost(loginNovaParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @param {ChangePassWordParam} [changePassWordParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiLegalxtractChangePasswordPost(changePassWordParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckUserStatusParam} [checkUserStatusParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: any): AxiosPromise<UserStatusResultOperationResult> {
            return localVarFp.apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginAppParam} [loginAppParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiLegalxtractLoginAppPost(loginAppParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLogoutPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractLogoutPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginNovaParam} [loginNovaParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.apiLegalxtractValidateNovaTokenPost(loginNovaParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @param {ChangePassWordParam} [changePassWordParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractChangePasswordPost(changePassWordParam?: ChangePassWordParam, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractChangePasswordPost(changePassWordParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckUserStatusParam} [checkUserStatusParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractCheckUserStatusPost(checkUserStatusParam?: CheckUserStatusParam, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractCheckUserStatusPost(checkUserStatusParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginAppParam} [loginAppParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractLoginAppPost(loginAppParam?: LoginAppParam, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractLoginAppPost(loginAppParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractLogoutPost(options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractLogoutPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginNovaParam} [loginNovaParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public apiLegalxtractValidateNovaTokenPost(loginNovaParam?: LoginNovaParam, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).apiLegalxtractValidateNovaTokenPost(loginNovaParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddNotePost: async (noteTextParam?: NoteTextParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteTextParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteNotePost: async (noteId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (noteId !== undefined) {
                localVarQueryParameter['noteId'] = noteId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditNotePost: async (noteTextParam?: NoteTextParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteTextParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lấy danh sách Note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListNoteGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetListNote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddNotePost(noteTextParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteNotePost(noteId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteNotePost(noteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditNotePost(noteTextParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lấy danh sách Note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetListNoteGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteTextObj>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetListNoteGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractAddNotePost(noteTextParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [noteId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteNotePost(noteId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteNotePost(noteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NoteTextParam} [noteTextParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractEditNotePost(noteTextParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lấy danh sách Note
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetListNoteGet(options?: any): AxiosPromise<Array<NoteTextObj>> {
            return localVarFp.apiLegalxtractGetListNoteGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * 
     * @param {NoteTextParam} [noteTextParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractAddNotePost(noteTextParam?: NoteTextParam, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractAddNotePost(noteTextParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [noteId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractDeleteNotePost(noteId?: string, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractDeleteNotePost(noteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NoteTextParam} [noteTextParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractEditNotePost(noteTextParam?: NoteTextParam, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractEditNotePost(noteTextParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lấy danh sách Note
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public apiLegalxtractGetListNoteGet(options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).apiLegalxtractGetListNoteGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NovaApi - axios parameter creator
 * @export
 */
export const NovaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [requestID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReturnUrlGet: async (requestID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetReturnUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (requestID !== undefined) {
                localVarQueryParameter['requestID'] = requestID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSessionTimeOutGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetSessionTimeOut`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NovaApi - functional programming interface
 * @export
 */
export const NovaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NovaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [requestID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetReturnUrlGet(requestID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetReturnUrlGet(requestID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetSessionTimeOutGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetSessionTimeOutGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NovaApi - factory interface
 * @export
 */
export const NovaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NovaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [requestID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReturnUrlGet(requestID?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractGetReturnUrlGet(requestID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSessionTimeOutGet(options?: any): AxiosPromise<number> {
            return localVarFp.apiLegalxtractGetSessionTimeOutGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NovaApi - object-oriented interface
 * @export
 * @class NovaApi
 * @extends {BaseAPI}
 */
export class NovaApi extends BaseAPI {
    /**
     * 
     * @param {string} [requestID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovaApi
     */
    public apiLegalxtractGetReturnUrlGet(requestID?: string, options?: AxiosRequestConfig) {
        return NovaApiFp(this.configuration).apiLegalxtractGetReturnUrlGet(requestID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NovaApi
     */
    public apiLegalxtractGetSessionTimeOutGet(options?: AxiosRequestConfig) {
        return NovaApiFp(this.configuration).apiLegalxtractGetSessionTimeOutGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OcrApi - axios parameter creator
 * @export
 */
export const OcrApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary hủy ocr
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelOcrPost: async (ocrId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CancelOcr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ocrId !== undefined) {
                localVarQueryParameter['ocrId'] = ocrId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckOcrQueueExistsPost: async (ocrId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckOcrQueueExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ocrId !== undefined) {
                localVarQueryParameter['ocrId'] = ocrId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thực hiện OCR
         * @param {OcrProcessParam} [ocrProcessParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOcrProcessPost: async (ocrProcessParam?: OcrProcessParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/OcrProcess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ocrProcessParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReadFileOcrParam} [readFileOcrParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReadFileOCrDonePost: async (readFileOcrParam?: ReadFileOcrParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ReadFileOCrDone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(readFileOcrParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OcrApi - functional programming interface
 * @export
 */
export const OcrApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OcrApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary hủy ocr
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCancelOcrPost(ocrId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCancelOcrPost(ocrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckOcrQueueExistsPost(ocrId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckOcrQueueExistsPost(ocrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thực hiện OCR
         * @param {OcrProcessParam} [ocrProcessParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractOcrProcessPost(ocrProcessParam?: OcrProcessParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TabOcrResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractOcrProcessPost(ocrProcessParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReadFileOcrParam} [readFileOcrParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractReadFileOCrDonePost(readFileOcrParam?: ReadFileOcrParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractReadFileOCrDonePost(readFileOcrParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OcrApi - factory interface
 * @export
 */
export const OcrApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OcrApiFp(configuration)
    return {
        /**
         * 
         * @summary hủy ocr
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelOcrPost(ocrId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractCancelOcrPost(ocrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckOcrQueueExistsPost(ocrId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractCheckOcrQueueExistsPost(ocrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thực hiện OCR
         * @param {OcrProcessParam} [ocrProcessParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOcrProcessPost(ocrProcessParam?: OcrProcessParam, options?: any): AxiosPromise<TabOcrResult> {
            return localVarFp.apiLegalxtractOcrProcessPost(ocrProcessParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReadFileOcrParam} [readFileOcrParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReadFileOCrDonePost(readFileOcrParam?: ReadFileOcrParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractReadFileOCrDonePost(readFileOcrParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OcrApi - object-oriented interface
 * @export
 * @class OcrApi
 * @extends {BaseAPI}
 */
export class OcrApi extends BaseAPI {
    /**
     * 
     * @summary hủy ocr
     * @param {string} [ocrId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcrApi
     */
    public apiLegalxtractCancelOcrPost(ocrId?: string, options?: AxiosRequestConfig) {
        return OcrApiFp(this.configuration).apiLegalxtractCancelOcrPost(ocrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ocrId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcrApi
     */
    public apiLegalxtractCheckOcrQueueExistsPost(ocrId?: string, options?: AxiosRequestConfig) {
        return OcrApiFp(this.configuration).apiLegalxtractCheckOcrQueueExistsPost(ocrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thực hiện OCR
     * @param {OcrProcessParam} [ocrProcessParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcrApi
     */
    public apiLegalxtractOcrProcessPost(ocrProcessParam?: OcrProcessParam, options?: AxiosRequestConfig) {
        return OcrApiFp(this.configuration).apiLegalxtractOcrProcessPost(ocrProcessParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReadFileOcrParam} [readFileOcrParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OcrApi
     */
    public apiLegalxtractReadFileOCrDonePost(readFileOcrParam?: ReadFileOcrParam, options?: AxiosRequestConfig) {
        return OcrApiFp(this.configuration).apiLegalxtractReadFileOCrDonePost(readFileOcrParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OpeningApi - axios parameter creator
 * @export
 */
export const OpeningApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [checkExist] 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAppendFilesPost: async (checkExist?: boolean, requestFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AppendFiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (checkExist !== undefined) {
                localVarQueryParameter['checkExist'] = checkExist;
            }

            if (requestFiles) {
                requestFiles.forEach((element) => {
                    localVarFormParams.append('RequestFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseAllTabFileGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseAllTabFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseOneTabFileGet: async (tabUniqueId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CloseOneTabFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabUniqueId !== undefined) {
                localVarQueryParameter['tabUniqueId'] = tabUniqueId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Danh sách các file được hỗ trợ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractFileTypeSupportGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/FileTypeSupport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Docuvieware HtmlString
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocuViewareControlPost: async (requestFiles?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDocuViewareControl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (requestFiles) {
                requestFiles.forEach((element) => {
                    localVarFormParams.append('RequestFiles', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetStatusProcessOpenPost: async (requestId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetStatusProcessOpen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (requestId !== undefined) {
                localVarQueryParameter['requestId'] = requestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenXtractOcrGet: async (ocrId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/OpenXtractOcr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ocrId !== undefined) {
                localVarQueryParameter['ocrId'] = ocrId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRenameTabGet: async (tabUniqueId?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RenameTab`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabUniqueId !== undefined) {
                localVarQueryParameter['tabUniqueId'] = tabUniqueId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpeningApi - functional programming interface
 * @export
 */
export const OpeningApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpeningApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [checkExist] 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAppendFilesPost(checkExist?: boolean, requestFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInitApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAppendFilesPost(checkExist, requestFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseAllTabFileGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseAllTabFileGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCloseOneTabFileGet(tabUniqueId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCloseOneTabFileGet(tabUniqueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Danh sách các file được hỗ trợ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractFileTypeSupportGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractFileTypeSupportGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Docuvieware HtmlString
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDocuViewareControlPost(requestFiles?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInitApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDocuViewareControlPost(requestFiles, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetStatusProcessOpenPost(requestId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatusOpenFile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetStatusProcessOpenPost(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractOpenXtractOcrGet(ocrId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInitApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractOpenXtractOcrGet(ocrId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRenameTabGet(tabUniqueId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OpeningApi - factory interface
 * @export
 */
export const OpeningApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpeningApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [checkExist] 
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAppendFilesPost(checkExist?: boolean, requestFiles?: Array<File>, options?: any): AxiosPromise<DocuviewareInitApiResult> {
            return localVarFp.apiLegalxtractAppendFilesPost(checkExist, requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseAllTabFileGet(options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractCloseAllTabFileGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCloseOneTabFileGet(tabUniqueId?: string, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractCloseOneTabFileGet(tabUniqueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Danh sách các file được hỗ trợ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractFileTypeSupportGet(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.apiLegalxtractFileTypeSupportGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Docuvieware HtmlString
         * @param {Array<File>} [requestFiles] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDocuViewareControlPost(requestFiles?: Array<File>, options?: any): AxiosPromise<DocuviewareInitApiResult> {
            return localVarFp.apiLegalxtractGetDocuViewareControlPost(requestFiles, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [requestId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetStatusProcessOpenPost(requestId?: string, options?: any): AxiosPromise<StatusOpenFile> {
            return localVarFp.apiLegalxtractGetStatusProcessOpenPost(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [ocrId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractOpenXtractOcrGet(ocrId?: string, options?: any): AxiosPromise<DocuviewareInitApiResult> {
            return localVarFp.apiLegalxtractOpenXtractOcrGet(ocrId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Đóng 1 tab
         * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractRenameTabGet(tabUniqueId, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OpeningApi - object-oriented interface
 * @export
 * @class OpeningApi
 * @extends {BaseAPI}
 */
export class OpeningApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [checkExist] 
     * @param {Array<File>} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractAppendFilesPost(checkExist?: boolean, requestFiles?: Array<File>, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractAppendFilesPost(checkExist, requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractCloseAllTabFileGet(options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractCloseAllTabFileGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Đóng 1 tab
     * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractCloseOneTabFileGet(tabUniqueId?: string, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractCloseOneTabFileGet(tabUniqueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Danh sách các file được hỗ trợ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractFileTypeSupportGet(options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractFileTypeSupportGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Docuvieware HtmlString
     * @param {Array<File>} [requestFiles] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractGetDocuViewareControlPost(requestFiles?: Array<File>, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractGetDocuViewareControlPost(requestFiles, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [requestId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractGetStatusProcessOpenPost(requestId?: string, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractGetStatusProcessOpenPost(requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [ocrId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractOpenXtractOcrGet(ocrId?: string, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractOpenXtractOcrGet(ocrId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Đóng 1 tab
     * @param {string} [tabUniqueId] TabId (TabDetails.UniqueId)
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpeningApi
     */
    public apiLegalxtractRenameTabGet(tabUniqueId?: string, name?: string, options?: AxiosRequestConfig) {
        return OpeningApiFp(this.configuration).apiLegalxtractRenameTabGet(tabUniqueId, name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeletePagesParam} [deletePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeletePagesPost: async (deletePagesParam?: DeletePagesParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeletePages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deletePagesParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDisplayPagePost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DisplayPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeletePagesParam} [deletePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeletePagesPost(deletePagesParam?: DeletePagesParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeletePagesPost(deletePagesParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDisplayPagePost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDisplayPagePost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * 
         * @param {DeletePagesParam} [deletePagesParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeletePagesPost(deletePagesParam?: DeletePagesParam, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractDeletePagesPost(deletePagesParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDisplayPagePost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractDisplayPagePost(page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI {
    /**
     * 
     * @param {DeletePagesParam} [deletePagesParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiLegalxtractDeletePagesPost(deletePagesParam?: DeletePagesParam, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiLegalxtractDeletePagesPost(deletePagesParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public apiLegalxtractDisplayPagePost(page?: number, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).apiLegalxtractDisplayPagePost(page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageNumberingApi - axios parameter creator
 * @export
 */
export const PageNumberingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessPageNumberingPost: async (pageNumberingInfo?: PageNumberingInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessPageNumbering`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pageNumberingInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageNumberingApi - functional programming interface
 * @export
 */
export const PageNumberingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageNumberingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageNumberingApi - factory interface
 * @export
 */
export const PageNumberingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageNumberingApiFp(configuration)
    return {
        /**
         * 
         * @param {PageNumberingInfo} [pageNumberingInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageNumberingApi - object-oriented interface
 * @export
 * @class PageNumberingApi
 * @extends {BaseAPI}
 */
export class PageNumberingApi extends BaseAPI {
    /**
     * 
     * @param {PageNumberingInfo} [pageNumberingInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageNumberingApi
     */
    public apiLegalxtractProcessPageNumberingPost(pageNumberingInfo?: PageNumberingInfo, options?: AxiosRequestConfig) {
        return PageNumberingApiFp(this.configuration).apiLegalxtractProcessPageNumberingPost(pageNumberingInfo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PreviewApi - axios parameter creator
 * @export
 */
export const PreviewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClosePreviewGet: async (sectionID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ClosePreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sectionID !== undefined) {
                localVarQueryParameter['sectionID'] = sectionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetFileViewerGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetFileViewer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailsGet: async (sectionID?: string, fromPage?: number, toPage?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetThumbnails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sectionID !== undefined) {
                localVarQueryParameter['sectionID'] = sectionID;
            }

            if (fromPage !== undefined) {
                localVarQueryParameter['fromPage'] = fromPage;
            }

            if (toPage !== undefined) {
                localVarQueryParameter['toPage'] = toPage;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailsPreviewGet: async (sectionID?: string, fromPage?: number, toPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetThumbnailsPreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sectionID !== undefined) {
                localVarQueryParameter['sectionID'] = sectionID;
            }

            if (fromPage !== undefined) {
                localVarQueryParameter['fromPage'] = fromPage;
            }

            if (toPage !== undefined) {
                localVarQueryParameter['toPage'] = toPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPreviewGet: async (sectionID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/Preview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sectionID !== undefined) {
                localVarQueryParameter['sectionID'] = sectionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveTempPreviewGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveTempPreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitFileBeforePreviewAsyncPost: async (extractAndSplitParam?: ExtractAndSplitParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SplitFileBeforePreviewAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extractAndSplitParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreviewApi - functional programming interface
 * @export
 */
export const PreviewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreviewApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractClosePreviewGet(sectionID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractClosePreviewGet(sectionID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetFileViewerGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuViewareRESTOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetFileViewerGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetThumbnailsGet(sectionID?: string, fromPage?: number, toPage?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThumbnailResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetThumbnailsGet(sectionID, fromPage, toPage, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetThumbnailsPreviewGet(sectionID?: string, fromPage?: number, toPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThumbnailResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetThumbnailsPreviewGet(sectionID, fromPage, toPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractPreviewGet(sectionID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuViewareRESTOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractPreviewGet(sectionID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveTempPreviewGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveTempPreviewGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtractSplit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreviewApi - factory interface
 * @export
 */
export const PreviewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreviewApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClosePreviewGet(sectionID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractClosePreviewGet(sectionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetFileViewerGet(fileName?: string, options?: any): AxiosPromise<DocuViewareRESTOutputResponse> {
            return localVarFp.apiLegalxtractGetFileViewerGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailsGet(sectionID?: string, fromPage?: number, toPage?: number, offset?: number, options?: any): AxiosPromise<ThumbnailResult> {
            return localVarFp.apiLegalxtractGetThumbnailsGet(sectionID, fromPage, toPage, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sectionID] 
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailsPreviewGet(sectionID?: string, fromPage?: number, toPage?: number, options?: any): AxiosPromise<ThumbnailResult> {
            return localVarFp.apiLegalxtractGetThumbnailsPreviewGet(sectionID, fromPage, toPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xử lý Preview
         * @param {string} [sectionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractPreviewGet(sectionID?: string, options?: any): AxiosPromise<DocuViewareRESTOutputResponse> {
            return localVarFp.apiLegalxtractPreviewGet(sectionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveTempPreviewGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRemoveTempPreviewGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam?: ExtractAndSplitParam, options?: any): AxiosPromise<Array<ExtractSplit>> {
            return localVarFp.apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreviewApi - object-oriented interface
 * @export
 * @class PreviewApi
 * @extends {BaseAPI}
 */
export class PreviewApi extends BaseAPI {
    /**
     * 
     * @summary xử lý Preview
     * @param {string} [sectionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractClosePreviewGet(sectionID?: string, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractClosePreviewGet(sectionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractGetFileViewerGet(fileName?: string, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractGetFileViewerGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sectionID] 
     * @param {number} [fromPage] 
     * @param {number} [toPage] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractGetThumbnailsGet(sectionID?: string, fromPage?: number, toPage?: number, offset?: number, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractGetThumbnailsGet(sectionID, fromPage, toPage, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sectionID] 
     * @param {number} [fromPage] 
     * @param {number} [toPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractGetThumbnailsPreviewGet(sectionID?: string, fromPage?: number, toPage?: number, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractGetThumbnailsPreviewGet(sectionID, fromPage, toPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xử lý Preview
     * @param {string} [sectionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractPreviewGet(sectionID?: string, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractPreviewGet(sectionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractRemoveTempPreviewGet(options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractRemoveTempPreviewGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtractAndSplitParam} [extractAndSplitParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreviewApi
     */
    public apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig) {
        return PreviewApiFp(this.configuration).apiLegalxtractSplitFileBeforePreviewAsyncPost(extractAndSplitParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReorderApi - axios parameter creator
 * @export
 */
export const ReorderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary xử lý Reorder
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessReorderGet: async (fromTabIndex?: number, toTabIndex?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessReorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromTabIndex !== undefined) {
                localVarQueryParameter['fromTabIndex'] = fromTabIndex;
            }

            if (toTabIndex !== undefined) {
                localVarQueryParameter['toTabIndex'] = toTabIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReorderApi - functional programming interface
 * @export
 */
export const ReorderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReorderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary xử lý Reorder
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReorderApi - factory interface
 * @export
 */
export const ReorderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReorderApiFp(configuration)
    return {
        /**
         * 
         * @summary xử lý Reorder
         * @param {number} [fromTabIndex] 
         * @param {number} [toTabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReorderApi - object-oriented interface
 * @export
 * @class ReorderApi
 * @extends {BaseAPI}
 */
export class ReorderApi extends BaseAPI {
    /**
     * 
     * @summary xử lý Reorder
     * @param {number} [fromTabIndex] 
     * @param {number} [toTabIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReorderApi
     */
    public apiLegalxtractProcessReorderGet(fromTabIndex?: number, toTabIndex?: number, options?: AxiosRequestConfig) {
        return ReorderApiFp(this.configuration).apiLegalxtractProcessReorderGet(fromTabIndex, toTabIndex, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReplaceApi - axios parameter creator
 * @export
 */
export const ReplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<ReplaceParamWeb>} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplacePost: async (replaceParamWeb?: Array<ReplaceParamWeb>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lấy danh sách Replace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateReplaceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CalculateReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceDelete: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Nháy phải vào từ đang được đánh dấu là replace chọn delete
         * @param {number} [viewerPageNo] 
         * @param {string} [replaceObjId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceFromWordGet: async (viewerPageNo?: number, replaceObjId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplaceFromWord`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }

            if (replaceObjId !== undefined) {
                localVarQueryParameter['replaceObjId'] = replaceObjId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceTooltipGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplaceTooltip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thay đổi replace (edit trên grid hoặc nháy phải chọn edit replace)
         * @param {ReplaceParamWeb} [replaceParamWeb] OriginalWords; ReplaceBy; ForeColor; BackColor; FontSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplacePost: async (replaceParamWeb?: ReplaceParamWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceParamWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lấy danh sách những chữ được highlight liên tiếp từ chữ ban đầu được chọn. chữ được chọn có thể đã được highlight hoặc chưa.  Nếu Highlight thì hàm sẽ trả về những word được highlight liên tiếp. nếu chưa thì chỉ trả về word hiện tại.  Dùng cho AddReplace=>ReplaceParam.Words
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordContinuousPost: async (getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetSelectedWordContinuous`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSelectedWordContinuousParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordMaskPost: async (rectangleSelection?: Array<RectangleSelection>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetSelectedWordMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rectangleSelection, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReplaceTooltipWeb} [replaceTooltipWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowReplacePost: async (replaceTooltipWeb?: ReplaceTooltipWeb, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowReplace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceTooltipWeb, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReplaceApi - functional programming interface
 * @export
 */
export const ReplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<ReplaceParamWeb>} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplacePost(replaceParamWeb?: Array<ReplaceParamWeb>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplacePost(replaceParamWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lấy danh sách Replace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCalculateReplaceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplaceObjDisplay>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCalculateReplaceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceDelete(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReplaceParamWeb>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceDelete(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Nháy phải vào từ đang được đánh dấu là replace chọn delete
         * @param {number} [viewerPageNo] 
         * @param {string} [replaceObjId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo?: number, replaceObjId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UndoDeleteReplaceFromWord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo, replaceObjId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceTooltipGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceTooltipGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thay đổi replace (edit trên grid hoặc nháy phải chọn edit replace)
         * @param {ReplaceParamWeb} [replaceParamWeb] OriginalWords; ReplaceBy; ForeColor; BackColor; FontSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceParamWeb>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditReplacePost(replaceParamWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lấy danh sách những chữ được highlight liên tiếp từ chữ ban đầu được chọn. chữ được chọn có thể đã được highlight hoặc chưa.  Nếu Highlight thì hàm sẽ trả về những word được highlight liên tiếp. nếu chưa thì chỉ trả về word hiện tại.  Dùng cho AddReplace=>ReplaceParam.Words
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectedWordContinuousResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetSelectedWordMaskPost(rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectedWordContinuousResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetSelectedWordMaskPost(rectangleSelection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReplaceTooltipWeb} [replaceTooltipWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowReplacePost(replaceTooltipWeb?: ReplaceTooltipWeb, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowReplacePost(replaceTooltipWeb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReplaceApi - factory interface
 * @export
 */
export const ReplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReplaceApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<ReplaceParamWeb>} [replaceParamWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplacePost(replaceParamWeb?: Array<ReplaceParamWeb>, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.apiLegalxtractAddReplacePost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lấy danh sách Replace
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateReplaceGet(options?: any): AxiosPromise<Array<ReplaceObjDisplay>> {
            return localVarFp.apiLegalxtractCalculateReplaceGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceDelete(requestBody?: Array<string>, options?: any): AxiosPromise<Array<ReplaceParamWeb>> {
            return localVarFp.apiLegalxtractDeleteReplaceDelete(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Nháy phải vào từ đang được đánh dấu là replace chọn delete
         * @param {number} [viewerPageNo] 
         * @param {string} [replaceObjId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo?: number, replaceObjId?: string, options?: any): AxiosPromise<UndoDeleteReplaceFromWord> {
            return localVarFp.apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo, replaceObjId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceTooltipGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractDeleteReplaceTooltipGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thay đổi replace (edit trên grid hoặc nháy phải chọn edit replace)
         * @param {ReplaceParamWeb} [replaceParamWeb] OriginalWords; ReplaceBy; ForeColor; BackColor; FontSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: any): AxiosPromise<ReplaceParamWeb> {
            return localVarFp.apiLegalxtractEditReplacePost(replaceParamWeb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lấy danh sách những chữ được highlight liên tiếp từ chữ ban đầu được chọn. chữ được chọn có thể đã được highlight hoặc chưa.  Nếu Highlight thì hàm sẽ trả về những word được highlight liên tiếp. nếu chưa thì chỉ trả về word hiện tại.  Dùng cho AddReplace=>ReplaceParam.Words
         * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: any): AxiosPromise<SelectedWordContinuousResponse> {
            return localVarFp.apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<RectangleSelection>} [rectangleSelection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetSelectedWordMaskPost(rectangleSelection?: Array<RectangleSelection>, options?: any): AxiosPromise<SelectedWordContinuousResponse> {
            return localVarFp.apiLegalxtractGetSelectedWordMaskPost(rectangleSelection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReplaceTooltipWeb} [replaceTooltipWeb] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowReplacePost(replaceTooltipWeb?: ReplaceTooltipWeb, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractShowReplacePost(replaceTooltipWeb, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReplaceApi - object-oriented interface
 * @export
 * @class ReplaceApi
 * @extends {BaseAPI}
 */
export class ReplaceApi extends BaseAPI {
    /**
     * 
     * @param {Array<ReplaceParamWeb>} [replaceParamWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractAddReplacePost(replaceParamWeb?: Array<ReplaceParamWeb>, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractAddReplacePost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lấy danh sách Replace
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractCalculateReplaceGet(options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractCalculateReplaceGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractDeleteReplaceDelete(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractDeleteReplaceDelete(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Nháy phải vào từ đang được đánh dấu là replace chọn delete
     * @param {number} [viewerPageNo] 
     * @param {string} [replaceObjId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo?: number, replaceObjId?: string, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractDeleteReplaceFromWordGet(viewerPageNo, replaceObjId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractDeleteReplaceTooltipGet(options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractDeleteReplaceTooltipGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thay đổi replace (edit trên grid hoặc nháy phải chọn edit replace)
     * @param {ReplaceParamWeb} [replaceParamWeb] OriginalWords; ReplaceBy; ForeColor; BackColor; FontSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractEditReplacePost(replaceParamWeb?: ReplaceParamWeb, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractEditReplacePost(replaceParamWeb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lấy danh sách những chữ được highlight liên tiếp từ chữ ban đầu được chọn. chữ được chọn có thể đã được highlight hoặc chưa.  Nếu Highlight thì hàm sẽ trả về những word được highlight liên tiếp. nếu chưa thì chỉ trả về word hiện tại.  Dùng cho AddReplace=>ReplaceParam.Words
     * @param {GetSelectedWordContinuousParam} [getSelectedWordContinuousParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam?: GetSelectedWordContinuousParam, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractGetSelectedWordContinuousPost(getSelectedWordContinuousParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<RectangleSelection>} [rectangleSelection] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractGetSelectedWordMaskPost(rectangleSelection?: Array<RectangleSelection>, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractGetSelectedWordMaskPost(rectangleSelection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReplaceTooltipWeb} [replaceTooltipWeb] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceApi
     */
    public apiLegalxtractShowReplacePost(replaceTooltipWeb?: ReplaceTooltipWeb, options?: AxiosRequestConfig) {
        return ReplaceApiFp(this.configuration).apiLegalxtractShowReplacePost(replaceTooltipWeb, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReplaceBoxApi - axios parameter creator
 * @export
 */
export const ReplaceBoxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceBoxPost: async (viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(replaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceBoxTooltipGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteReplaceBoxTooltip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplaceBoxPost: async (editReplaceBoxParam?: EditReplaceBoxParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editReplaceBoxParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview Box trả về base 64
         * @param {string} [annotationId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPreviewReplateBoxGet: async (annotationId?: string, viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetPreviewReplateBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (annotationId !== undefined) {
                localVarQueryParameter['annotationId'] = annotationId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReplaceBoxObjsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetReplaceBoxObjs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annotationId] 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveReplaceBoxGet: async (annotationId?: string, boxId?: string, viewerPageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (annotationId !== undefined) {
                localVarQueryParameter['annotationId'] = annotationId;
            }

            if (boxId !== undefined) {
                localVarQueryParameter['boxId'] = boxId;
            }

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowReplaceBoxTooltipGet: async (id?: string, pageNo?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowReplaceBoxTooltip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (pageNo !== undefined) {
                localVarQueryParameter['pageNo'] = pageNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoActionRemoveReplaceBoxPost: async (viewerPageNo?: number, undoRemoveReplaceBox?: UndoRemoveReplaceBox, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UndoActionRemoveReplaceBox`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (viewerPageNo !== undefined) {
                localVarQueryParameter['viewerPageNo'] = viewerPageNo;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(undoRemoveReplaceBox, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReplaceBoxApi - functional programming interface
 * @export
 */
export const ReplaceBoxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReplaceBoxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddReplaceBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceBoxParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddReplaceBoxPost(viewerPageNo, replaceBoxParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteReplaceBoxTooltipGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteReplaceBoxTooltipGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceBoxParam>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Preview Box trả về base 64
         * @param {string} [annotationId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetPreviewReplateBoxGet(annotationId?: string, viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetPreviewReplateBoxGet(annotationId, viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetReplaceBoxObjsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplaceBoxObjs>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetReplaceBoxObjsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [annotationId] 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveReplaceBoxGet(annotationId?: string, boxId?: string, viewerPageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UndoRemoveReplaceBox>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveReplaceBoxGet(annotationId, boxId, viewerPageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowReplaceBoxTooltipGet(id?: string, pageNo?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowReplaceBoxTooltipGet(id, pageNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo?: number, undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BoxObj>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo, undoRemoveReplaceBox, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReplaceBoxApi - factory interface
 * @export
 */
export const ReplaceBoxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReplaceBoxApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {ReplaceBoxParam} [replaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddReplaceBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: any): AxiosPromise<ReplaceBoxParam> {
            return localVarFp.apiLegalxtractAddReplaceBoxPost(viewerPageNo, replaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteReplaceBoxTooltipGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDeleteReplaceBoxTooltipGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: any): AxiosPromise<ReplaceBoxParam> {
            return localVarFp.apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview Box trả về base 64
         * @param {string} [annotationId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetPreviewReplateBoxGet(annotationId?: string, viewerPageNo?: number, options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractGetPreviewReplateBoxGet(annotationId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetReplaceBoxObjsGet(options?: any): AxiosPromise<ReplaceBoxObjs> {
            return localVarFp.apiLegalxtractGetReplaceBoxObjsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annotationId] 
         * @param {string} [boxId] 
         * @param {number} [viewerPageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveReplaceBoxGet(annotationId?: string, boxId?: string, viewerPageNo?: number, options?: any): AxiosPromise<UndoRemoveReplaceBox> {
            return localVarFp.apiLegalxtractRemoveReplaceBoxGet(annotationId, boxId, viewerPageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {number} [pageNo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowReplaceBoxTooltipGet(id?: string, pageNo?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractShowReplaceBoxTooltipGet(id, pageNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [viewerPageNo] 
         * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo?: number, undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: any): AxiosPromise<BoxObj> {
            return localVarFp.apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo, undoRemoveReplaceBox, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReplaceBoxApi - object-oriented interface
 * @export
 * @class ReplaceBoxApi
 * @extends {BaseAPI}
 */
export class ReplaceBoxApi extends BaseAPI {
    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {ReplaceBoxParam} [replaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractAddReplaceBoxPost(viewerPageNo?: number, replaceBoxParam?: ReplaceBoxParam, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractAddReplaceBoxPost(viewerPageNo, replaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractDeleteReplaceBoxTooltipGet(options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractDeleteReplaceBoxTooltipGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditReplaceBoxParam} [editReplaceBoxParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam?: EditReplaceBoxParam, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractEditReplaceBoxPost(editReplaceBoxParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview Box trả về base 64
     * @param {string} [annotationId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractGetPreviewReplateBoxGet(annotationId?: string, viewerPageNo?: number, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractGetPreviewReplateBoxGet(annotationId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractGetReplaceBoxObjsGet(options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractGetReplaceBoxObjsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annotationId] 
     * @param {string} [boxId] 
     * @param {number} [viewerPageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractRemoveReplaceBoxGet(annotationId?: string, boxId?: string, viewerPageNo?: number, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractRemoveReplaceBoxGet(annotationId, boxId, viewerPageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {number} [pageNo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractShowReplaceBoxTooltipGet(id?: string, pageNo?: number, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractShowReplaceBoxTooltipGet(id, pageNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [viewerPageNo] 
     * @param {UndoRemoveReplaceBox} [undoRemoveReplaceBox] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReplaceBoxApi
     */
    public apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo?: number, undoRemoveReplaceBox?: UndoRemoveReplaceBox, options?: AxiosRequestConfig) {
        return ReplaceBoxApiFp(this.configuration).apiLegalxtractUndoActionRemoveReplaceBoxPost(viewerPageNo, undoRemoveReplaceBox, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RotateApi - axios parameter creator
 * @export
 */
export const RotateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Rotate trái
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateLeftPost: async (rotateParam?: RotateParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RotateLeft`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rotateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rotate phải
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateRightPost: async (rotateParam?: RotateParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RotateRight`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rotateParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RotateApi - functional programming interface
 * @export
 */
export const RotateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RotateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Rotate trái
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRotateLeftPost(rotateParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Rotate phải
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRotateRightPost(rotateParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RotateApi - factory interface
 * @export
 */
export const RotateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RotateApiFp(configuration)
    return {
        /**
         * 
         * @summary Rotate trái
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRotateLeftPost(rotateParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Rotate phải
         * @param {RotateParam} [rotateParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRotateRightPost(rotateParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RotateApi - object-oriented interface
 * @export
 * @class RotateApi
 * @extends {BaseAPI}
 */
export class RotateApi extends BaseAPI {
    /**
     * 
     * @summary Rotate trái
     * @param {RotateParam} [rotateParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RotateApi
     */
    public apiLegalxtractRotateLeftPost(rotateParam?: RotateParam, options?: AxiosRequestConfig) {
        return RotateApiFp(this.configuration).apiLegalxtractRotateLeftPost(rotateParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Rotate phải
     * @param {RotateParam} [rotateParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RotateApi
     */
    public apiLegalxtractRotateRightPost(rotateParam?: RotateParam, options?: AxiosRequestConfig) {
        return RotateApiFp(this.configuration).apiLegalxtractRotateRightPost(rotateParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaveNovaApi - axios parameter creator
 * @export
 */
export const SaveNovaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveRedactedNovaPost: async (fileName?: string, pdfFileType?: PdfFileType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveRedactedNova`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (pdfFileType !== undefined) {
                localVarQueryParameter['pdfFileType'] = pdfFileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractNovaPost: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveXtractNova`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaveNovaApi - functional programming interface
 * @export
 */
export const SaveNovaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaveNovaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveRedactedNovaPost(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveRedactedNovaPost(fileName, pdfFileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveXtractNovaPost(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveXtractNovaPost(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaveNovaApi - factory interface
 * @export
 */
export const SaveNovaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaveNovaApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveRedactedNovaPost(fileName?: string, pdfFileType?: PdfFileType, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractSaveRedactedNovaPost(fileName, pdfFileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractNovaPost(fileName?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractSaveXtractNovaPost(fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaveNovaApi - object-oriented interface
 * @export
 * @class SaveNovaApi
 * @extends {BaseAPI}
 */
export class SaveNovaApi extends BaseAPI {
    /**
     * 
     * @param {string} [fileName] 
     * @param {PdfFileType} [pdfFileType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveNovaApi
     */
    public apiLegalxtractSaveRedactedNovaPost(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig) {
        return SaveNovaApiFp(this.configuration).apiLegalxtractSaveRedactedNovaPost(fileName, pdfFileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveNovaApi
     */
    public apiLegalxtractSaveXtractNovaPost(fileName?: string, options?: AxiosRequestConfig) {
        return SaveNovaApiFp(this.configuration).apiLegalxtractSaveXtractNovaPost(fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaveS3Api - axios parameter creator
 * @export
 */
export const SaveS3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileToS3Post: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveExtractFileToS3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractPdfToS3Post: async (extractAndSplitParam?: ExtractAndSplitParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveXtractPdfToS3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extractAndSplitParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaveS3Api - functional programming interface
 * @export
 */
export const SaveS3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaveS3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveExtractFileToS3Post(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveExtractFileToS3Post(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaveS3Api - factory interface
 * @export
 */
export const SaveS3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaveS3ApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileToS3Post(fileName?: string, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiLegalxtractSaveExtractFileToS3Post(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam?: ExtractAndSplitParam, options?: any): AxiosPromise<BooleanOperationResult> {
            return localVarFp.apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaveS3Api - object-oriented interface
 * @export
 * @class SaveS3Api
 * @extends {BaseAPI}
 */
export class SaveS3Api extends BaseAPI {
    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveS3Api
     */
    public apiLegalxtractSaveExtractFileToS3Post(fileName?: string, options?: AxiosRequestConfig) {
        return SaveS3ApiFp(this.configuration).apiLegalxtractSaveExtractFileToS3Post(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtractAndSplitParam} [extractAndSplitParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveS3Api
     */
    public apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig) {
        return SaveS3ApiFp(this.configuration).apiLegalxtractSaveXtractPdfToS3Post(extractAndSplitParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaveToThirdPartyApi - axios parameter creator
 * @export
 */
export const SaveToThirdPartyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileToLCPost: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveExtractFileToLC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveRedactPdfLCPost: async (extractAndSplitParam?: ExtractAndSplitParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveRedactPdfLC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extractAndSplitParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaveToThirdPartyApi - functional programming interface
 * @export
 */
export const SaveToThirdPartyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaveToThirdPartyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveExtractFileToLCPost(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveXtractParamOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveExtractFileToLCPost(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaveRedactPdfHookParamOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaveToThirdPartyApi - factory interface
 * @export
 */
export const SaveToThirdPartyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaveToThirdPartyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileToLCPost(fileName?: string, options?: any): AxiosPromise<SaveXtractParamOperationResult> {
            return localVarFp.apiLegalxtractSaveExtractFileToLCPost(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam?: ExtractAndSplitParam, options?: any): AxiosPromise<SaveRedactPdfHookParamOperationResult> {
            return localVarFp.apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaveToThirdPartyApi - object-oriented interface
 * @export
 * @class SaveToThirdPartyApi
 * @extends {BaseAPI}
 */
export class SaveToThirdPartyApi extends BaseAPI {
    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveToThirdPartyApi
     */
    public apiLegalxtractSaveExtractFileToLCPost(fileName?: string, options?: AxiosRequestConfig) {
        return SaveToThirdPartyApiFp(this.configuration).apiLegalxtractSaveExtractFileToLCPost(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtractAndSplitParam} [extractAndSplitParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaveToThirdPartyApi
     */
    public apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig) {
        return SaveToThirdPartyApiFp(this.configuration).apiLegalxtractSaveRedactPdfLCPost(extractAndSplitParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SavingApi - axios parameter creator
 * @export
 */
export const SavingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckSettingWatchdogPost: async (calculateWatchDogParam?: CalculateWatchDogParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckSettingWatchdog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateWatchDogParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCombinePdfPost: async (calculateWatchDogParam?: CalculateWatchDogParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CombinePdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateWatchDogParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExtractFilePost: async (extractAndSplitParam?: ExtractAndSplitParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExtractFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extractAndSplitParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExtractFilePrintGet: async (fileName?: string, pdfFileType?: PdfFileType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExtractFilePrint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (pdfFileType !== undefined) {
                localVarQueryParameter['pdfFileType'] = pdfFileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveAsPdfGet: async (fileName?: string, pdfFileType?: PdfFileType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveAsPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (pdfFileType !== undefined) {
                localVarQueryParameter['pdfFileType'] = pdfFileType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveAsZipGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveAsZip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xử lý file Output .xtract
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveExtractFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavingApi - functional programming interface
 * @export
 */
export const SavingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCombinePdfPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCombinePdfPost(calculateWatchDogParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExtractFilePost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExtractFilePost(extractAndSplitParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExtractFilePrintGet(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExtractFilePrintGet(fileName, pdfFileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveAsPdfGet(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveAsPdfGet(fileName, pdfFileType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveAsZipGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveAsZipGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xử lý file Output .xtract
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveExtractFileGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveExtractFileGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SavingApi - factory interface
 * @export
 */
export const SavingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavingApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: any): AxiosPromise<StringListApiResult> {
            return localVarFp.apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCombinePdfPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractCombinePdfPost(calculateWatchDogParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExtractAndSplitParam} [extractAndSplitParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExtractFilePost(extractAndSplitParam?: ExtractAndSplitParam, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExtractFilePost(extractAndSplitParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExtractFilePrintGet(fileName?: string, pdfFileType?: PdfFileType, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractExtractFilePrintGet(fileName, pdfFileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {PdfFileType} [pdfFileType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveAsPdfGet(fileName?: string, pdfFileType?: PdfFileType, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractSaveAsPdfGet(fileName, pdfFileType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveAsZipGet(fileName?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractSaveAsZipGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xử lý file Output .xtract
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveExtractFileGet(fileName?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractSaveExtractFileGet(fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SavingApi - object-oriented interface
 * @export
 * @class SavingApi
 * @extends {BaseAPI}
 */
export class SavingApi extends BaseAPI {
    /**
     * 
     * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractCheckSettingWatchdogPost(calculateWatchDogParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractCombinePdfPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractCombinePdfPost(calculateWatchDogParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExtractAndSplitParam} [extractAndSplitParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractExtractFilePost(extractAndSplitParam?: ExtractAndSplitParam, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractExtractFilePost(extractAndSplitParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {PdfFileType} [pdfFileType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractExtractFilePrintGet(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractExtractFilePrintGet(fileName, pdfFileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {PdfFileType} [pdfFileType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractSaveAsPdfGet(fileName?: string, pdfFileType?: PdfFileType, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractSaveAsPdfGet(fileName, pdfFileType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractSaveAsZipGet(fileName?: string, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractSaveAsZipGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xử lý file Output .xtract
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavingApi
     */
    public apiLegalxtractSaveExtractFileGet(fileName?: string, options?: AxiosRequestConfig) {
        return SavingApiFp(this.configuration).apiLegalxtractSaveExtractFileGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelSearchGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CancelSearch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataSearchResultPagingGet: async (pageNumber?: number, take?: number, cacheKey?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetDataSearchResultPaging`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (take !== undefined) {
                localVarQueryParameter['take'] = take;
            }

            if (cacheKey !== undefined) {
                localVarQueryParameter['cacheKey'] = cacheKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tìm theo từ khóa
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchKeywordPost: async (searchKeywordApiParam?: SearchKeywordApiParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchKeyword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchKeywordApiParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchTagParam} [searchTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTagPost: async (searchTagParam?: SearchTagParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tìm kiếm theo template
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTemplatePost: async (searchTemplateApiParam?: SearchTemplateApiParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SearchTemplate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchTemplateApiParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCancelSearchGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCancelSearchGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tìm theo từ khóa
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchTagParam} [searchTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchTagPost(searchTagParam?: SearchTagParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchTagPost(searchTagParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tìm kiếm theo template
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResultData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCancelSearchGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractCancelSearchGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [take] 
         * @param {string} [cacheKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tìm theo từ khóa
         * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchTagParam} [searchTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTagPost(searchTagParam?: SearchTagParam, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchTagPost(searchTagParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tìm kiếm theo template
         * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: any): AxiosPromise<SearchResultData> {
            return localVarFp.apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractCancelSearchGet(options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractCancelSearchGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [take] 
     * @param {string} [cacheKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractGetDataSearchResultPagingGet(pageNumber?: number, take?: number, cacheKey?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractGetDataSearchResultPagingGet(pageNumber, take, cacheKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tìm theo từ khóa
     * @param {SearchKeywordApiParam} [searchKeywordApiParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchKeywordPost(searchKeywordApiParam?: SearchKeywordApiParam, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchKeywordPost(searchKeywordApiParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchTagParam} [searchTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchTagPost(searchTagParam?: SearchTagParam, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchTagPost(searchTagParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tìm kiếm theo template
     * @param {SearchTemplateApiParam} [searchTemplateApiParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiLegalxtractSearchTemplatePost(searchTemplateApiParam?: SearchTemplateApiParam, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiLegalxtractSearchTemplatePost(searchTemplateApiParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadSettingPost: async (redraw?: boolean, lxSetting?: LxSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UploadSetting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (redraw !== undefined) {
                localVarQueryParameter['redraw'] = redraw;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lxSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUploadSettingPost(redraw, lxSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @param {boolean} [redraw] 
         * @param {LxSetting} [lxSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractUploadSettingPost(redraw, lxSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @param {boolean} [redraw] 
     * @param {LxSetting} [lxSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public apiLegalxtractUploadSettingPost(redraw?: boolean, lxSetting?: LxSetting, options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).apiLegalxtractUploadSettingPost(redraw, lxSetting, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SplitDocumentApi - axios parameter creator
 * @export
 */
export const SplitDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitDocumentGet: async (splitFromViewerPageNo?: number, newTabFileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SplitDocument`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (splitFromViewerPageNo !== undefined) {
                localVarQueryParameter['splitFromViewerPageNo'] = splitFromViewerPageNo;
            }

            if (newTabFileName !== undefined) {
                localVarQueryParameter['newTabFileName'] = newTabFileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SplitDocumentApi - functional programming interface
 * @export
 */
export const SplitDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SplitDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SplitDocumentApi - factory interface
 * @export
 */
export const SplitDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SplitDocumentApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [splitFromViewerPageNo] 
         * @param {string} [newTabFileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SplitDocumentApi - object-oriented interface
 * @export
 * @class SplitDocumentApi
 * @extends {BaseAPI}
 */
export class SplitDocumentApi extends BaseAPI {
    /**
     * 
     * @param {number} [splitFromViewerPageNo] 
     * @param {string} [newTabFileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SplitDocumentApi
     */
    public apiLegalxtractSplitDocumentGet(splitFromViewerPageNo?: number, newTabFileName?: string, options?: AxiosRequestConfig) {
        return SplitDocumentApiFp(this.configuration).apiLegalxtractSplitDocumentGet(splitFromViewerPageNo, newTabFileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StampApi - axios parameter creator
 * @export
 */
export const StampApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add stamp 1 tab
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddStampPut: async (tabID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyOneStampPost: async (tabID?: string, tabStamp?: TabStamp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyOneStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tabStamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStampAllPagePost: async (showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ApplyStampAllPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (showFromToc !== undefined) {
                localVarQueryParameter['showFromToc'] = showFromToc;
            }

            if (indexFromToc !== undefined) {
                localVarQueryParameter['indexFromToc'] = indexFromToc;
            }

            if (tocIndexPattern !== undefined) {
                localVarQueryParameter['tocIndexPattern'] = tocIndexPattern;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tabStamp, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary thay đổi index
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeCustomStampIndexPost: async (tabID?: string, stampTitleIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ChangeCustomStampIndex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }

            if (stampTitleIndex !== undefined) {
                localVarQueryParameter['stampTitleIndex'] = stampTitleIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xóa Stamp
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteStampPost: async (tabID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tabID !== undefined) {
                localVarQueryParameter['tabID'] = tabID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllStampPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAllStamp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StampApi - functional programming interface
 * @export
 */
export const StampApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StampApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add stamp 1 tab
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddStampPut(tabID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddStampPut(tabID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyOneStampPost(tabID, tabStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary thay đổi index
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xóa Stamp
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteStampPost(tabID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteStampPost(tabID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAllStampPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAllStampPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StampApi - factory interface
 * @export
 */
export const StampApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StampApiFp(configuration)
    return {
        /**
         * 
         * @summary add stamp 1 tab
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddStampPut(tabID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractAddStampPut(tabID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tabID] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractApplyOneStampPost(tabID, tabStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [showFromToc] 
         * @param {boolean} [indexFromToc] 
         * @param {string} [tocIndexPattern] 
         * @param {TabStamp} [tabStamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary thay đổi index
         * @param {string} [tabID] 
         * @param {string} [stampTitleIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xóa Stamp
         * @param {string} [tabID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteStampPost(tabID?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractDeleteStampPost(tabID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllStampPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRemoveAllStampPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StampApi - object-oriented interface
 * @export
 * @class StampApi
 * @extends {BaseAPI}
 */
export class StampApi extends BaseAPI {
    /**
     * 
     * @summary add stamp 1 tab
     * @param {string} [tabID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractAddStampPut(tabID?: string, options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractAddStampPut(tabID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tabID] 
     * @param {TabStamp} [tabStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractApplyOneStampPost(tabID?: string, tabStamp?: TabStamp, options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractApplyOneStampPost(tabID, tabStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [showFromToc] 
     * @param {boolean} [indexFromToc] 
     * @param {string} [tocIndexPattern] 
     * @param {TabStamp} [tabStamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractApplyStampAllPagePost(showFromToc?: boolean, indexFromToc?: boolean, tocIndexPattern?: string, tabStamp?: TabStamp, options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractApplyStampAllPagePost(showFromToc, indexFromToc, tocIndexPattern, tabStamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary thay đổi index
     * @param {string} [tabID] 
     * @param {string} [stampTitleIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractChangeCustomStampIndexPost(tabID?: string, stampTitleIndex?: string, options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractChangeCustomStampIndexPost(tabID, stampTitleIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xóa Stamp
     * @param {string} [tabID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractDeleteStampPost(tabID?: string, options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractDeleteStampPost(tabID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StampApi
     */
    public apiLegalxtractRemoveAllStampPost(options?: AxiosRequestConfig) {
        return StampApiFp(this.configuration).apiLegalxtractRemoveAllStampPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TabApi - axios parameter creator
 * @export
 */
export const TabApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isTocPageBreak] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetIsTocPageBreakPost: async (id?: string, isTocPageBreak?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/setIsTocPageBreak`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (isTocPageBreak !== undefined) {
                localVarQueryParameter['isTocPageBreak'] = isTocPageBreak;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TabApi - functional programming interface
 * @export
 */
export const TabApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TabApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isTocPageBreak] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetIsTocPageBreakPost(id?: string, isTocPageBreak?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetIsTocPageBreakPost(id, isTocPageBreak, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TabApi - factory interface
 * @export
 */
export const TabApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TabApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {boolean} [isTocPageBreak] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetIsTocPageBreakPost(id?: string, isTocPageBreak?: boolean, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractSetIsTocPageBreakPost(id, isTocPageBreak, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TabApi - object-oriented interface
 * @export
 * @class TabApi
 * @extends {BaseAPI}
 */
export class TabApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {boolean} [isTocPageBreak] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TabApi
     */
    public apiLegalxtractSetIsTocPageBreakPost(id?: string, isTocPageBreak?: boolean, options?: AxiosRequestConfig) {
        return TabApiFp(this.configuration).apiLegalxtractSetIsTocPageBreakPost(id, isTocPageBreak, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagApi - axios parameter creator
 * @export
 */
export const TagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary add category
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagCategoryPost: async (addTagCategoryParam?: AddTagCategoryParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTagCategoryParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thêm tagName
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagNamePost: async (addTagNameParam?: AddTagNameParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/AddTagName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addTagNameParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary XÓA Category
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagCategoryGet: async (tagCategoryID?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tagCategoryID !== undefined) {
                localVarQueryParameter['tagCategoryID'] = tagCategoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagNamePost: async (tagId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTagName`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DeleteTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTagCategoryPost: async (tagCategory?: TagCategory, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTagCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xuất khẩu tag
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportTagDictionaryGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ExportTagDictionary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary nhập khẩu tag
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInportFromFilePost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/InportFromFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagDictionaryForClientGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoadTagDictionaryForClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary lấy thông tin các tabinfo
         * @param {string} [tagId] 
         * @param {string} [tagCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagInfoGet: async (tagId?: string, tagCategoryId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/LoadTagInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tagId !== undefined) {
                localVarQueryParameter['tagId'] = tagId;
            }

            if (tagCategoryId !== undefined) {
                localVarQueryParameter['tagCategoryId'] = tagCategoryId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllTagNotExistsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RemoveAllTagNotExists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thêm tag
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTagPost: async (saveTagParam?: SaveTagParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Thêm tag từ chữ trên tài liệu
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectionTagPost: async (selectionTagParam?: SelectionTagParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SelectionTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionTagParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagApi - functional programming interface
 * @export
 */
export const TagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary add category
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thêm tagName
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagNameListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractAddTagNamePost(addTagNameParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary XÓA Category
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagNamePost(tagId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagNamePost(tagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDeleteTagPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagCategoryListApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTagCategoryPost(tagCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xuất khẩu tag
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractExportTagDictionaryGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary nhập khẩu tag
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractInportFromFilePost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractInportFromFilePost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoadTagDictionaryForClientGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoadTagDictionaryForClientGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary lấy thông tin các tabinfo
         * @param {string} [tagId] 
         * @param {string} [tagCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractLoadTagInfoGet(tagId?: string, tagCategoryId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractLoadTagInfoGet(tagId, tagCategoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRemoveAllTagNotExistsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRemoveAllTagNotExistsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thêm tag
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringApiResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveTagPost(saveTagParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Thêm tag từ chữ trên tài liệu
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSelectionTagPost(selectionTagParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagApi - factory interface
 * @export
 */
export const TagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagApiFp(configuration)
    return {
        /**
         * 
         * @summary add category
         * @param {AddTagCategoryParam} [addTagCategoryParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: any): AxiosPromise<TagCategoryListApiResult> {
            return localVarFp.apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thêm tagName
         * @param {AddTagNameParam} [addTagNameParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: any): AxiosPromise<TagNameListApiResult> {
            return localVarFp.apiLegalxtractAddTagNamePost(addTagNameParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary XÓA Category
         * @param {string} [tagCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [tagId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagNamePost(tagId?: string, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractDeleteTagNamePost(tagId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: any): AxiosPromise<BooleanApiResult> {
            return localVarFp.apiLegalxtractDeleteTagPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TagCategory} [tagCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: any): AxiosPromise<TagCategoryListApiResult> {
            return localVarFp.apiLegalxtractEditTagCategoryPost(tagCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xuất khẩu tag
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractExportTagDictionaryGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary nhập khẩu tag
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractInportFromFilePost(file?: File, options?: any): AxiosPromise<TagData> {
            return localVarFp.apiLegalxtractInportFromFilePost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagDictionaryForClientGet(options?: any): AxiosPromise<TagData> {
            return localVarFp.apiLegalxtractLoadTagDictionaryForClientGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary lấy thông tin các tabinfo
         * @param {string} [tagId] 
         * @param {string} [tagCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractLoadTagInfoGet(tagId?: string, tagCategoryId?: string, options?: any): AxiosPromise<Array<TagInfo>> {
            return localVarFp.apiLegalxtractLoadTagInfoGet(tagId, tagCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRemoveAllTagNotExistsPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRemoveAllTagNotExistsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thêm tag
         * @param {SaveTagParam} [saveTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: any): AxiosPromise<StringApiResult> {
            return localVarFp.apiLegalxtractSaveTagPost(saveTagParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Thêm tag từ chữ trên tài liệu
         * @param {SelectionTagParam} [selectionTagParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractSelectionTagPost(selectionTagParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagApi - object-oriented interface
 * @export
 * @class TagApi
 * @extends {BaseAPI}
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @summary add category
     * @param {AddTagCategoryParam} [addTagCategoryParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractAddTagCategoryPost(addTagCategoryParam?: AddTagCategoryParam, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractAddTagCategoryPost(addTagCategoryParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thêm tagName
     * @param {AddTagNameParam} [addTagNameParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractAddTagNamePost(addTagNameParam?: AddTagNameParam, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractAddTagNamePost(addTagNameParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary XÓA Category
     * @param {string} [tagCategoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagCategoryGet(tagCategoryID?: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagCategoryGet(tagCategoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [tagId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagNamePost(tagId?: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagNamePost(tagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractDeleteTagPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractDeleteTagPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TagCategory} [tagCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractEditTagCategoryPost(tagCategory?: TagCategory, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractEditTagCategoryPost(tagCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xuất khẩu tag
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractExportTagDictionaryGet(fileName?: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractExportTagDictionaryGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary nhập khẩu tag
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractInportFromFilePost(file?: File, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractInportFromFilePost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractLoadTagDictionaryForClientGet(options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractLoadTagDictionaryForClientGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary lấy thông tin các tabinfo
     * @param {string} [tagId] 
     * @param {string} [tagCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractLoadTagInfoGet(tagId?: string, tagCategoryId?: string, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractLoadTagInfoGet(tagId, tagCategoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractRemoveAllTagNotExistsPost(options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractRemoveAllTagNotExistsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thêm tag
     * @param {SaveTagParam} [saveTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractSaveTagPost(saveTagParam?: SaveTagParam, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractSaveTagPost(saveTagParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Thêm tag từ chữ trên tài liệu
     * @param {SelectionTagParam} [selectionTagParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagApi
     */
    public apiLegalxtractSelectionTagPost(selectionTagParam?: SelectionTagParam, options?: AxiosRequestConfig) {
        return TagApiFp(this.configuration).apiLegalxtractSelectionTagPost(selectionTagParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestApi - axios parameter creator
 * @export
 */
export const TestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDocuViewareHealthCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/docuViewareHealthCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHealthCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/healthCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractTestGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/Test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestApi - functional programming interface
 * @export
 */
export const TestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDocuViewareHealthCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDocuViewareHealthCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractHealthCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractHealthCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractTestGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractTestGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestApi - factory interface
 * @export
 */
export const TestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDocuViewareHealthCheckGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractDocuViewareHealthCheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractHealthCheckGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractHealthCheckGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractTestGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiLegalxtractTestGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TestApi - object-oriented interface
 * @export
 * @class TestApi
 * @extends {BaseAPI}
 */
export class TestApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiLegalxtractDocuViewareHealthCheckGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiLegalxtractDocuViewareHealthCheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiLegalxtractHealthCheckGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiLegalxtractHealthCheckGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestApi
     */
    public apiLegalxtractTestGet(options?: AxiosRequestConfig) {
        return TestApiFp(this.configuration).apiLegalxtractTestGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ThirdPartyConnectApi - axios parameter creator
 * @export
 */
export const ThirdPartyConnectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [documentId] 
         * @param {string} [webHookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractThirdPartyConnectGet: async (documentId?: string, webHookId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ThirdPartyConnect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (documentId !== undefined) {
                localVarQueryParameter['documentId'] = documentId;
            }

            if (webHookId !== undefined) {
                localVarQueryParameter['webHookId'] = webHookId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThirdPartyConnectApi - functional programming interface
 * @export
 */
export const ThirdPartyConnectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThirdPartyConnectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [documentId] 
         * @param {string} [webHookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractThirdPartyConnectGet(documentId?: string, webHookId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponseOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractThirdPartyConnectGet(documentId, webHookId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ThirdPartyConnectApi - factory interface
 * @export
 */
export const ThirdPartyConnectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThirdPartyConnectApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [documentId] 
         * @param {string} [webHookId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractThirdPartyConnectGet(documentId?: string, webHookId?: string, options?: any): AxiosPromise<LoginResponseOperationResult> {
            return localVarFp.apiLegalxtractThirdPartyConnectGet(documentId, webHookId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThirdPartyConnectApi - object-oriented interface
 * @export
 * @class ThirdPartyConnectApi
 * @extends {BaseAPI}
 */
export class ThirdPartyConnectApi extends BaseAPI {
    /**
     * 
     * @param {string} [documentId] 
     * @param {string} [webHookId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThirdPartyConnectApi
     */
    public apiLegalxtractThirdPartyConnectGet(documentId?: string, webHookId?: string, options?: AxiosRequestConfig) {
        return ThirdPartyConnectApiFp(this.configuration).apiLegalxtractThirdPartyConnectGet(documentId, webHookId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ThumbnailApi - axios parameter creator
 * @export
 */
export const ThumbnailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckAllPageHideGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CheckAllPageHide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary chỉ lấy all page chỉ lấy ảnh của page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailAllGet: async (fromPage?: number, toPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetThumbnailAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromPage !== undefined) {
                localVarQueryParameter['fromPage'] = fromPage;
            }

            if (toPage !== undefined) {
                localVarQueryParameter['toPage'] = toPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary chỉ lấy page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailPagesGet: async (fromPage?: number, toPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetThumbnailPages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fromPage !== undefined) {
                localVarQueryParameter['fromPage'] = fromPage;
            }

            if (toPage !== undefined) {
                localVarQueryParameter['toPage'] = toPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetSplitOnExtractPost: async (setSplitOnExtractParam?: SetSplitOnExtractParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SetSplitOnExtract`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setSplitOnExtractParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ShowHidePageParam} [showHidePageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowHidePagePost: async (showHidePageParam?: ShowHidePageParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ShowHidePage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(showHidePageParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThumbnailApi - functional programming interface
 * @export
 */
export const ThumbnailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThumbnailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCheckAllPageHideGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCheckAllPageHideGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary chỉ lấy all page chỉ lấy ảnh của page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetThumbnailAllGet(fromPage?: number, toPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThumbnailResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetThumbnailAllGet(fromPage, toPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary chỉ lấy page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetThumbnailPagesGet(fromPage?: number, toPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThumbnailResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetThumbnailPagesGet(fromPage, toPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ShowHidePageParam} [showHidePageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractShowHidePagePost(showHidePageParam?: ShowHidePageParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractShowHidePagePost(showHidePageParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ThumbnailApi - factory interface
 * @export
 */
export const ThumbnailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThumbnailApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCheckAllPageHideGet(options?: any): AxiosPromise<boolean> {
            return localVarFp.apiLegalxtractCheckAllPageHideGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary chỉ lấy all page chỉ lấy ảnh của page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailAllGet(fromPage?: number, toPage?: number, options?: any): AxiosPromise<Array<ThumbnailResult>> {
            return localVarFp.apiLegalxtractGetThumbnailAllGet(fromPage, toPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary chỉ lấy page trong khoảng
         * @param {number} [fromPage] 
         * @param {number} [toPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailPagesGet(fromPage?: number, toPage?: number, options?: any): AxiosPromise<Array<ThumbnailResult>> {
            return localVarFp.apiLegalxtractGetThumbnailPagesGet(fromPage, toPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ShowHidePageParam} [showHidePageParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractShowHidePagePost(showHidePageParam?: ShowHidePageParam, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractShowHidePagePost(showHidePageParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThumbnailApi - object-oriented interface
 * @export
 * @class ThumbnailApi
 * @extends {BaseAPI}
 */
export class ThumbnailApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractCheckAllPageHideGet(options?: AxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractCheckAllPageHideGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary chỉ lấy all page chỉ lấy ảnh của page trong khoảng
     * @param {number} [fromPage] 
     * @param {number} [toPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractGetThumbnailAllGet(fromPage?: number, toPage?: number, options?: AxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractGetThumbnailAllGet(fromPage, toPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary chỉ lấy page trong khoảng
     * @param {number} [fromPage] 
     * @param {number} [toPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractGetThumbnailPagesGet(fromPage?: number, toPage?: number, options?: AxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractGetThumbnailPagesGet(fromPage, toPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SetSplitOnExtractParam} [setSplitOnExtractParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam?: SetSplitOnExtractParam, options?: AxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractSetSplitOnExtractPost(setSplitOnExtractParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ShowHidePageParam} [showHidePageParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThumbnailApi
     */
    public apiLegalxtractShowHidePagePost(showHidePageParam?: ShowHidePageParam, options?: AxiosRequestConfig) {
        return ThumbnailApiFp(this.configuration).apiLegalxtractShowHidePagePost(showHidePageParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TocApi - axios parameter creator
 * @export
 */
export const TocApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDrawTocContentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/DrawTocContent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocHeaderPost: async (editTocHeaderParam?: EditTocHeaderParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTocHeader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editTocHeaderParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocRowPost: async (tocRow?: TocRow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/EditTocRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tocRow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lấy thông tin danh sách TOC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTocRowGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetTocRow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary xử lý TOC
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessTocGet: async (isShowToc?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ProcessToc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (isShowToc !== undefined) {
                localVarQueryParameter['isShowToc'] = isShowToc;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReOrderTOCPost: async (reOrderTOCParam?: ReOrderTOCParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ReOrderTOC`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reOrderTOCParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTocSettingsPost: async (tocSettings?: TocSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/SaveTocSettings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tocSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TocApi - functional programming interface
 * @export
 */
export const TocApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TocApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractDrawTocContentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractDrawTocContentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractEditTocRowPost(tocRow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lấy thông tin danh sách TOC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetTocRowGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TocRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetTocRowGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary xử lý TOC
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractProcessTocGet(isShowToc, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReOrderTOCResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractReOrderTOCPost(reOrderTOCParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuviewareInit>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractSaveTocSettingsPost(tocSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TocApi - factory interface
 * @export
 */
export const TocApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TocApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractDrawTocContentGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractDrawTocContentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EditTocHeaderParam} [editTocHeaderParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TocRow} [tocRow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractEditTocRowPost(tocRow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lấy thông tin danh sách TOC
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTocRowGet(options?: any): AxiosPromise<Array<TocRow>> {
            return localVarFp.apiLegalxtractGetTocRowGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary xử lý TOC
         * @param {boolean} [isShowToc] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractProcessTocGet(isShowToc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReOrderTOCParam} [reOrderTOCParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: any): AxiosPromise<ReOrderTOCResponse> {
            return localVarFp.apiLegalxtractReOrderTOCPost(reOrderTOCParam, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TocSettings} [tocSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: any): AxiosPromise<DocuviewareInit> {
            return localVarFp.apiLegalxtractSaveTocSettingsPost(tocSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TocApi - object-oriented interface
 * @export
 * @class TocApi
 * @extends {BaseAPI}
 */
export class TocApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractDrawTocContentGet(options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractDrawTocContentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EditTocHeaderParam} [editTocHeaderParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractEditTocHeaderPost(editTocHeaderParam?: EditTocHeaderParam, options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractEditTocHeaderPost(editTocHeaderParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TocRow} [tocRow] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractEditTocRowPost(tocRow?: TocRow, options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractEditTocRowPost(tocRow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lấy thông tin danh sách TOC
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractGetTocRowGet(options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractGetTocRowGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary xử lý TOC
     * @param {boolean} [isShowToc] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractProcessTocGet(isShowToc?: boolean, options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractProcessTocGet(isShowToc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReOrderTOCParam} [reOrderTOCParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractReOrderTOCPost(reOrderTOCParam?: ReOrderTOCParam, options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractReOrderTOCPost(reOrderTOCParam, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TocSettings} [tocSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TocApi
     */
    public apiLegalxtractSaveTocSettingsPost(tocSettings?: TocSettings, options?: AxiosRequestConfig) {
        return TocApiFp(this.configuration).apiLegalxtractSaveTocSettingsPost(tocSettings, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Thông tin các tab đang sử dụng
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTabDetailsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetTabDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lấy danh sách OcrWord trong trang hiện tại
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetWordByPageNoGet: async (currentPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetWordByPageNo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRedrawPagesGet: async (currentPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/RedrawPages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrackingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Thông tin các tab đang sử dụng
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetTabDetailsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TabDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetTabDetailsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lấy danh sách OcrWord trong trang hiện tại
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetWordByPageNoGet(currentPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WordInPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetWordByPageNoGet(currentPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractRedrawPagesGet(currentPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractRedrawPagesGet(currentPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrackingApiFp(configuration)
    return {
        /**
         * 
         * @summary Thông tin các tab đang sử dụng
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetTabDetailsGet(options?: any): AxiosPromise<Array<TabDetail>> {
            return localVarFp.apiLegalxtractGetTabDetailsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lấy danh sách OcrWord trong trang hiện tại
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetWordByPageNoGet(currentPage?: number, options?: any): AxiosPromise<WordInPage> {
            return localVarFp.apiLegalxtractGetWordByPageNoGet(currentPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [currentPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractRedrawPagesGet(currentPage?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractRedrawPagesGet(currentPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * 
     * @summary Thông tin các tab đang sử dụng
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public apiLegalxtractGetTabDetailsGet(options?: AxiosRequestConfig) {
        return TrackingApiFp(this.configuration).apiLegalxtractGetTabDetailsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lấy danh sách OcrWord trong trang hiện tại
     * @param {number} [currentPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public apiLegalxtractGetWordByPageNoGet(currentPage?: number, options?: AxiosRequestConfig) {
        return TrackingApiFp(this.configuration).apiLegalxtractGetWordByPageNoGet(currentPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [currentPage] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public apiLegalxtractRedrawPagesGet(currentPage?: number, options?: AxiosRequestConfig) {
        return TrackingApiFp(this.configuration).apiLegalxtractRedrawPagesGet(currentPage, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpLoadFileToS3Api - axios parameter creator
 * @export
 */
export const UpLoadFileToS3ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadFilePost: async (files?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/UploadFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpLoadFileToS3Api - functional programming interface
 * @export
 */
export const UpLoadFileToS3ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpLoadFileToS3ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractUploadFilePost(files?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringListOperationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractUploadFilePost(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpLoadFileToS3Api - factory interface
 * @export
 */
export const UpLoadFileToS3ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpLoadFileToS3ApiFp(configuration)
    return {
        /**
         * 
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractUploadFilePost(files?: Array<File>, options?: any): AxiosPromise<StringListOperationResult> {
            return localVarFp.apiLegalxtractUploadFilePost(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpLoadFileToS3Api - object-oriented interface
 * @export
 * @class UpLoadFileToS3Api
 * @extends {BaseAPI}
 */
export class UpLoadFileToS3Api extends BaseAPI {
    /**
     * 
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpLoadFileToS3Api
     */
    public apiLegalxtractUploadFilePost(files?: Array<File>, options?: AxiosRequestConfig) {
        return UpLoadFileToS3ApiFp(this.configuration).apiLegalxtractUploadFilePost(files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WatchDogApi - axios parameter creator
 * @export
 */
export const WatchDogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateWatchDogPost: async (calculateWatchDogParam?: CalculateWatchDogParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/CalculateWatchDog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calculateWatchDogParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WatchDogApi - functional programming interface
 * @export
 */
export const WatchDogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WatchDogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculateWatchDogResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WatchDogApi - factory interface
 * @export
 */
export const WatchDogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WatchDogApiFp(configuration)
    return {
        /**
         * 
         * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: any): AxiosPromise<CalculateWatchDogResponse> {
            return localVarFp.apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WatchDogApi - object-oriented interface
 * @export
 * @class WatchDogApi
 * @extends {BaseAPI}
 */
export class WatchDogApi extends BaseAPI {
    /**
     * 
     * @param {CalculateWatchDogParam} [calculateWatchDogParam] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WatchDogApi
     */
    public apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam?: CalculateWatchDogParam, options?: AxiosRequestConfig) {
        return WatchDogApiFp(this.configuration).apiLegalxtractCalculateWatchDogPost(calculateWatchDogParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * XmlInvoiceApi - axios parameter creator
 * @export
 */
export const XmlInvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClosePreviewInvoidXMLGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/ClosePreviewInvoidXML`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetFilePreviewInvoidXMLGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetFilePreviewInvoidXML`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetInvoicePDFGet: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetInvoicePDF`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetInvoiceXMLPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetInvoiceXML`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailPreviewInvoidXMLPost: async (fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetThumbnailPreviewInvoidXML`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetXmlPreviewItemPost: async (requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/legalxtract/GetXmlPreviewItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XmlInvoiceApi - functional programming interface
 * @export
 */
export const XmlInvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XmlInvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractClosePreviewInvoidXMLGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractClosePreviewInvoidXMLGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetFilePreviewInvoidXMLGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocuViewareRESTOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetFilePreviewInvoidXMLGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetInvoicePDFGet(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetInvoicePDFGet(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetInvoiceXMLPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetInvoiceXMLPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDetail>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiLegalxtractGetXmlPreviewItemPost(requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetXmlPreviewItemResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiLegalxtractGetXmlPreviewItemPost(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * XmlInvoiceApi - factory interface
 * @export
 */
export const XmlInvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XmlInvoiceApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractClosePreviewInvoidXMLGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiLegalxtractClosePreviewInvoidXMLGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetFilePreviewInvoidXMLGet(fileName?: string, options?: any): AxiosPromise<DocuViewareRESTOutputResponse> {
            return localVarFp.apiLegalxtractGetFilePreviewInvoidXMLGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetInvoicePDFGet(fileName?: string, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractGetInvoicePDFGet(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetInvoiceXMLPost(requestBody?: Array<string>, options?: any): AxiosPromise<File> {
            return localVarFp.apiLegalxtractGetInvoiceXMLPost(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName?: string, options?: any): AxiosPromise<Array<ImageDetail>> {
            return localVarFp.apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLegalxtractGetXmlPreviewItemPost(requestBody?: Array<string>, options?: any): AxiosPromise<Array<GetXmlPreviewItemResponse>> {
            return localVarFp.apiLegalxtractGetXmlPreviewItemPost(requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XmlInvoiceApi - object-oriented interface
 * @export
 * @class XmlInvoiceApi
 * @extends {BaseAPI}
 */
export class XmlInvoiceApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractClosePreviewInvoidXMLGet(options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractClosePreviewInvoidXMLGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractGetFilePreviewInvoidXMLGet(fileName?: string, options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractGetFilePreviewInvoidXMLGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractGetInvoicePDFGet(fileName?: string, options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractGetInvoicePDFGet(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractGetInvoiceXMLPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractGetInvoiceXMLPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName?: string, options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractGetThumbnailPreviewInvoidXMLPost(fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} [requestBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XmlInvoiceApi
     */
    public apiLegalxtractGetXmlPreviewItemPost(requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return XmlInvoiceApiFp(this.configuration).apiLegalxtractGetXmlPreviewItemPost(requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}


