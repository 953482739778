import * as React from "react";
import { useLang } from "../../../i18n/useLang";
import {
  Checkbox,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  PrimaryButton,
  SelectionMode,
} from "@fluentui/react";
import { Icon } from "@fluentui/react/lib/Icon";
import { CheckboxVisibility, Selection } from "@fluentui/react/lib/DetailsList";
import * as Api from "../../../ApiV2";
import { documentService } from "../../../service/document";
import { noteService } from "../../../service/noteService";
import { DataCache } from "../../../config/constant";
import { StyledContainer } from "../../../assets/style/left-sidebar/note-panel";
import { useRecoilState } from "recoil";
import {
  IPageState,
  dialogState,
  docuviewareMainState,
  modalContainerState,
  noteState,
  pageSelectedState,
} from "../../../recoil";
import { DocuVieware } from "../../../recoil/docuviewareState";
import { approvalStampState } from "../../../recoil/approvalStampState";
import { approvalStampService } from "../../../service/approvalStampService";
import ApprovalStampTemplateModal from "../../../modal/ApprovalStamp/ApprovalStampTemplateModal";
const ApprovalStampApi = new Api.ApprovalStampApi();
export const ApprovalStampPanel = () => {
  const { GetListApprovalStamp } = approvalStampService();
  const [docuviewareMain] = useRecoilState<DocuVieware>(docuviewareMainState);
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [modalContainer, setModalContainerState] =
    useRecoilState(modalContainerState);
  const [pageState, setPageState] =
    useRecoilState<IPageState>(pageSelectedState);
  const [approvalStampCurrentState, setApprovalStampState] =
    useRecoilState(approvalStampState);
  const [showModal, setShowModal] = React.useState<{
    show: boolean;
  }>({ show: false });
  const { tr } = useLang();
  React.useEffect(() => {
    GetListApprovalStamp();
  }, []);
  const COLUMNS: IColumn[] = [
    {
      key: "docNo",
      name: tr("docNo"),
      fieldName: "Word",
      minWidth: 80,
      isRowHeader: true,
      className: "note-content",
      isResizable: true,
      isPadded: true,
      onRender: (item: Api.ApprovalStampViewItem) => {
        return (
          <div
            onDoubleClick={() => {
              item_click(item);
            }}
          >
            {item.docNo}
          </div>
        );
      },
    },
    {
      key: "date",
      name: tr("date"),
      fieldName: "date",
      minWidth: 50,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: Api.ApprovalStampViewItem) => {
        return (
          <div
            onDoubleClick={() => {
              item_click(item);
            }}
          >
            {item.date}
          </div>
        );
      },
    },
    {
      key: "page",
      name: tr("page"),
      fieldName: "page",
      minWidth: 20,
      isRowHeader: true,
      isResizable: true,
      data: "string",
      isPadded: true,
      onRender: (item: Api.ApprovalStampViewItem) => {
        return (
          <div
            onDoubleClick={() => {
              item_click(item);
            }}
          >
            {item.pageNo}
          </div>
        );
      },
    },
    {
      key: "edit",
      name: "",
      fieldName: "edit",
      minWidth: 9,
      maxWidth: 9,
      onRender: (item: Api.ApprovalStampViewItem) => {
        return (
          <div onClick={() => onOpenEditApprovalStampModal(item)}>
            <Icon
              iconName="Edit"
              style={{ cursor: "pointer", fontSize: "17px", width: "17px" }}
            />
          </div>
        );
      },
      isPadded: false,
    },
    {
      key: "delete",
      name: "",
      fieldName: "delete",
      minWidth: 9,
      maxWidth: 9,
      onRender: (item: Api.ApprovalStampViewItem) => {
        return (
          <Icon
            onClick={() => {
              onDeleteApprovalStamp(item);
            }}
            iconName="Delete"
            style={{ cursor: "pointer", fontSize: "17px", width: "17px" }}
          />
        );
      },
      isPadded: false,
    },
  ];
  const item_click = (item: Api.ApprovalStampViewItem) => {
    DocuViewareAPI.SelectPage(DataCache.docuViewareID(), item?.pageNo);
    setPageState((cur) => {
      return {
        ...cur,
        currentPage: item?.pageNo || 1,
      };
    });
  };
  React.useEffect(() => {
    if ((docuviewareMain?.DocuviewareInit?.tabDetails || []).length > 0) {
      refreshApprovalStampList();
    } else {
      setApprovalStampState({
        ...approvalStampCurrentState,
        ApprovalStampViewItems: [],
      });
    }
  }, []);

  const onOpenEditApprovalStampModal = (
    item: Api.ApprovalStampViewItem
  ): void => {
    setApprovalStampState((cur) => {
      return { ...cur, EditApprovalStampInfo: item || null, isAdd: false };
    });
    setTimeout(() => {
      setModalContainerState((cur) => {
        return {
          ...cur,
          showApprovalStampModal: true,
        };
      });
    }, 300);
  };

  const onDeleteApprovalStamp = (item: Api.ApprovalStampViewItem) => {
    setDialogState({
      ...dialog,
      MsgDialog: tr("doYouWantToDelete"),
      CallBackConfirmDialog: {
        action: () => {
          ApprovalStampApi.apiLegalxtractRemoveApprovalStampDelete(
            item.pageNo,
            item.id
          ).then((res) => {
            documentService.RefreshPage(null, DataCache.docuViewareID());
            setApprovalStampState((cur) => {
              return {
                ...cur,
                ApprovalStampViewItems: res.data,
              };
            });
          });
        },
      },
      Type: "ConfirmationDialog",
    });
  };
  const refreshApprovalStampList = () => {
    GetListApprovalStamp();
  };
  return (
    <StyledContainer>
      <div className="header-left-panel">
        {tr("approvalStamp")}
        <div style={{ flex: 1 }}></div>
        <PrimaryButton
          text={tr("template")}
          style={{
            width: 110,
          }}
          onClick={() => {
            setShowModal({ show: true });
          }}
          allowDisabledFocus
        ></PrimaryButton>
      </div>
      <div
        style={{
          height: "calc(100% - 39px)",
          overflow: "auto",
        }}
      >
        <DetailsList
          items={approvalStampCurrentState.ApprovalStampViewItems}
          columns={COLUMNS}
          setKey="none"
          layoutMode={DetailsListLayoutMode.justified}
          checkboxVisibility={CheckboxVisibility.hidden}
          isHeaderVisible={true}
          onRenderRow={(prop, defaultRender) => {
            return (
              <div
                onDoubleClick={() => {
                  if (prop) item_click(prop?.item as Api.ApprovalStampViewItem);
                }}
              >
                {defaultRender && defaultRender(prop)}
              </div>
            );
          }}
          onColumnHeaderClick={(ev, column) => {
            console.log("ssss");
          }}
        />
        {showModal.show && (
          <ApprovalStampTemplateModal
            show={showModal.show}
            onClose={() => {
              setShowModal({ show: false });
            }}
          ></ApprovalStampTemplateModal>
        )}
      </div>
    </StyledContainer>
  );
};
