import * as React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { App } from "./App";
import Login from "./components/login/login";
import { userService } from "./service/userService";
import { useEffect } from "react";
import {
  CompanyCode,
  Constants,
  DataCache,
  LocalStorageKey,
} from "./config/constant";
import Cookies from "js-cookie";
import * as Api from "./ApiV2";
import { Guid } from "guid-typescript";
import { axiosInstance } from "./config/axios-config";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { getParameterByName } from "./utils/helpers";
import { DesignReport } from "./components/designReport/DesignReport";
const {
  REACT_APP_IsDev,
  REACT_APP_ConfigAppUrl,
  REACT_APP_SENTRY_DNS,
  NODE_ENV,
} = process.env;
function AppRoute() {
  const [loadedConfig, SetLoadedConfig] = React.useState(false);
  const { getUser } = userService();
  useEffect(() => {
    // @ts-ignore
    window["IsDev"] = REACT_APP_IsDev;
    // @ts-ignore
    window["ConfigAppUrl"] = REACT_APP_ConfigAppUrl;
    // @ts-ignore
    // window["REACT_APP_SENTRY_DNS"] = REACT_APP_SENTRY_DNS;
    // @ts-ignore
    // window["UserInfo"] = LocalStorageKey.UserInfo;
    localStorage.removeItem(LocalStorageKey.Token);
    let identifiedKey = "";
    let configUrl = `http://localhost:44316/Connect/`;
    if (
      !REACT_APP_IsDev ||
      (REACT_APP_IsDev == "false" &&
        window.origin.indexOf("localhost") < 0 &&
        window.origin.indexOf("kubernetes.docker.internal") < 0)
    ) {
      // @ts-ignore
      configUrl = `${window.origin}${window["ConfigAppUrl"]}`;
    }
    axiosInstance.get(configUrl + "GetConfigApp").then(async (res) => {
      DataCache.REACT_APP_API_ENDPOINT = res.data.legalXtractAPIUrl;
      DataCache.REACT_APP_CompanyCode = (
        res.data.companyCode as string
      ).toLocaleLowerCase();
      DataCache.OCRAPI = res.data.apiocr;
      DataCache.RegisterLink = res.data.registerLink;
      // @ts-ignore
      window["REACT_APP_API_ENDPOINT"] = res.data.legalXtractAPIUrl;
      // @ts-ignore
      window["AppCode"] = DataCache.REACT_APP_CompanyCode;
      if (!DataCache.REACT_APP_CompanyCode) {
        const user = getUser();
        if (
          !user &&
          window.location.href.toLocaleLowerCase().indexOf("/login") < 0
        ) {
          window.location.href = "/login";
        }
      } else if (DataCache.REACT_APP_CompanyCode == CompanyCode.Nova) {
        if (!Cookies.get(Constants.TokenThirdParty)) {
          window.location.href = configUrl + "Error";
        } else {
          axiosInstance.post(
            DataCache.OCRAPI + "/api/legalxtractocr/setconfignova",
            {
              requestId: Cookies.get(Constants.RequestId) || "",
              tokenThirdParty: Cookies.get(Constants.TokenThirdParty),
            },
            {
              headers: {
                "Content-Type": "application/json-patch+json",
              },
            }
          );
          const res1 = await axiosInstance.post(
            DataCache.REACT_APP_API_ENDPOINT +
              "/api/legalxtract/ValidateNovaToken",
            {
              requestId: Cookies.get(Constants.RequestId) || "",
              tokenThirdParty: Cookies.get(Constants.TokenThirdParty),
              email: Cookies.get(Constants.Email) || "",
              fullName: Cookies.get(Constants.FullName) || "",
              licenseKey: Cookies.get(Constants.LicenseKey) || "",
              userId: Cookies.get(Constants.UserId) || "",
            },
            {
              headers: {
                IdentifiedKey: Cookies.get(Constants.RequestId) || "",
                "Content-Type": "application/json-patch+json",
              },
            }
          );
          identifiedKey = Cookies.get(Constants.RequestId) || "";
          if (res1.data) {
            localStorage.setItem(
              LocalStorageKey.UserInfo,
              JSON.stringify(res1.data)
            );
          }
        }
      } else if (DataCache.REACT_APP_CompanyCode == CompanyCode.lc) {
        const href = window.location.href;
        identifiedKey = getParameterByName("docId", href) || "";
        if (!identifiedKey) {
          identifiedKey = localStorage.IdentifiedKey.toString();
        }
        const res1 = await axiosInstance.get(
          DataCache.REACT_APP_API_ENDPOINT +
            `/api/legalxtract/thirdpartyconnect?documentId=${identifiedKey}`
        );
        if (!res1.data.ErrorMessage && res1.data.result) {
          localStorage.setItem(
            LocalStorageKey.UserInfo,
            JSON.stringify(res1.data.result)
          );
        }
      }
      if (identifiedKey && identifiedKey != undefined) {
        localStorage.setItem(LocalStorageKey.IdentifiedKey, identifiedKey);
      } else if (!localStorage.IdentifiedKey) {
        localStorage.setItem(
          LocalStorageKey.IdentifiedKey,
          Guid.create().toString()
        );
      }
      i18n
        .use(Backend)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          supportedLngs: ["de", "en", "da"],
          debug: false,
          interpolation: {
            escapeValue: false,
          },
          backend: {
            loadPath:
              DataCache.REACT_APP_API_ENDPOINT +
              "/api/legalxtract/GetLanguages/{{lng}}",
            customHeaders: () => ({
              IdentifiedKey: localStorage.IdentifiedKey.toString(),
            }),
          },
        })
        .then(async () => {});
      setTimeout(async () => {
        SetLoadedConfig(true);
      }, 200);
    });
  }, []);
  if (!loadedConfig) {
    return <></>;
  }
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App></App>} />
          <Route path="/designreport" element={<DesignReport></DesignReport>} />
          {!DataCache.REACT_APP_CompanyCode && (
            <Route path="/login" element={<Login></Login>} />
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default AppRoute;
